import React, { useState } from "react";
import "./table.scss";

const Table = (props) => {
  const { Recommendation = "", Label = "" } = props.data;
  console.log(Recommendation, Label);
  const [Columns, setColumns] = useState([]);
  const COLUMNS = [
    {
      title: "CROP",
      field: "crop",
    },
    {
      title: "MAJOR PEST",
      field: "major_pest",
    },
    {
      title: "DOSE (ml/acre)",
      field: "dose",
    },
    {
      title: "DILUTION (L/acre)",
      field: "dilution",
    },
    {
      title: "PHI",
      field: "phi",
    },
  ];

  const rows = [
    {
      crop: "Paddy",
      major_pest:
        "Leaf and neck blast disease, Stem borer, brown plant hopper, green leaf hopper, Whorl maggot.",
      dose: 400,
      dilution: 200,
      phi: "58 Days",
    },
  ];

  return (
    <table>
      <thead>
        <tr>
          <th>{Label[0]["Crop Name"]}</th>
          <th>{Label[0].headerType}</th>
          <th>{Label[0].Dose}</th>
          <th>{Label[0].Dilution}</th>
          <th>{Label[0].PHI}</th>
        </tr>
      </thead>
      <tbody>
        {Recommendation.map((row, index) => {
          return (
            <tr key={index}>
              <td>{row.CropName}</td>
              <td>{row.headerType}</td>
              <td>
                <p>{row.Dose}</p>
              </td>
              <td>{row.Dilution}</td>
              <td>{row.PHI}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Table;
