import { APICALL } from "api/ApiCall";
import { PRODUCTTYPELIST, CROPLIST } from "api/Constants";
import { getLanguageId } from "api/Utils";
import React, { useState } from "react";
import "./sidebar.scss";

const Sidebar = (props) => {
  const { headingText, data, type } = props;
  let name = "";
  const [clickedItem, setClickedItem] = useState(0);
  const key = localStorage.getItem("key");
  const languageId = localStorage.getItem("languageId");
  const [sideBarData, setSidebarData] = React.useState([]);
  const [productID, setproductID] = React.useState("");

  React.useEffect(() => {
    function handleResize() {
      if (window.innerWidth > "767") {
        document.querySelector(".menu").style.display = "block";
      } else {
        document.querySelector(".menu").style.display = "none";
      }
    }
    window.addEventListener("resize", handleResize);

    if (type == 1) {
      fetchProductList();
    } else {
      fetchCropList();
    }
    var url_string = window.location.href;
    var url = new URL(url_string);
    var urlParams = url.searchParams.get("id");
    const productTypeId = urlParams && urlParams[0] ? urlParams[0] : -1;
    setproductID(productTypeId);
  }, []);

  const fetchProductList = async () => {
    const productListData = await APICALL(PRODUCTTYPELIST, [
      { name: "languageId", value: getLanguageId() },
    ]);
    if (!productListData.data) return alert("No Data Found");
    let customizeProducts =
      productListData &&
      productListData.data.map((e) => {
        let dt = {
          ...e,
          name: e.ProductTypeName,
          image: e.ImgPath,
          id: e.ProductTypeId,
        };
        return dt;
      });
    setSidebarData(customizeProducts);
  };

  const fetchCropList = async () => {
    const productListData = await APICALL(CROPLIST, [
      { name: "languageId", value: getLanguageId() },
    ]);
    if (!productListData.data) return alert("No Data Found");
    let customizeProducts =
      productListData &&
      productListData.data.Crop.map((e) => {
        let dt = {
          ...e,
          name: e.CropName,
          image: e.ImgPath,
          id: e.CropId,
        };
        return dt;
      });
    setSidebarData(customizeProducts);
  };

  const handlerItemClick = (buttonClickProp, data, sideBarItem = false) => {
    console.log(document.getElementById(buttonClickProp.target));
    if (type == 1) {
      const { ProductTypeId = null, isSubCategory = 0 } = data;
      if (isSubCategory == 1) {
        window.location.href = `/ProductsSubCat?id=${ProductTypeId}`;
      } else {
        window.location.href = `/ProductSubChildCategory?id=${ProductTypeId}_${0}`;
      }
    } else {
      const { CropId = null } = data;
      window.location.href = `/CropStage?id=${CropId}`;
    }
  };

  const handleSidebar = (e) => {
    const hambergerMenu = document.querySelector(".menu");
    if (hambergerMenu.style.display == "block") {
      hambergerMenu.style.display = "none";
    } else {
      hambergerMenu.style.display = "block";
    }
  };

  const onAllPress = () => {
    if (type == 1) {
      window.location.href = `/AllProducts`;
    } else {
      window.location.href = `/Crops`;
    }
  };
  let convertLanVal = languageId.replace(/'/g, " ").trim();
  const CatName =
    type == 1
      ? convertLanVal == 1
        ? "उत्पाद श्रेणियाँ"
        : "Product Categories"
      : convertLanVal == 1
      ? "फसल श्रेणियाँ"
      : "Crop Categories";
  const allitemName =
    type == 1
      ? convertLanVal == 1
        ? "सभी उत्पाद श्रेणियाँ"
        : "All Product Categories"
      : convertLanVal == 1
      ? "सभी फसल श्रेणियाँ"
      : "All Crop Categories";
  return (
    <div className="sidebar">
      <div className="hamburger-menu" onClick={() => handleSidebar()}>
        <img src={process.env.PUBLIC_URL + "/hamburgerIcon.svg"} alt="" />
      </div>
      <div className="menu">
        <div className="menuHeading">{CatName}</div>
        <ul>
          <li>
            <a
              className={`${productID == "-1" ? "active" : ""}`}
              onClick={() => onAllPress()}
              id={`${type + "-all"}`}
            >
              {allitemName}
            </a>
          </li>
          {sideBarData.map((e, index) => {
            return (
              <li key={index}>
                <a
                  id={`${type + "-" + e.id}`}
                  onClick={(buttonClickProp) =>
                    handlerItemClick(buttonClickProp, e)
                  }
                  className={`${productID == e.id ? "active" : ""}`}
                >
                  {e.name ? e.name : "-"}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
