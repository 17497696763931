import React, { useState, useEffect, useRef } from "react";
import Input from "../../ui-components/Input/Input";
import "./mobileVerify.scss";
import OTPBox from "../OtpBox/OtpBox";
import { APICALL } from "../../api/ApiCall";
import { GETOTP } from "api/Constants";

const MobileVerify = () => {
  const [mobile, setMobile] = useState("");
  const [error, setError] = useState("");
  const [mobileSuccess, setMobileSuccess] = useState(true);
  const [userId, setUserId] = useState("");
  const [userType, setUserType] = useState("");
  const mobileRef = useRef(null);

  useEffect(() => {
    mobileRef.current.focus();
  },[])

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = await APICALL(GETOTP, [{ name: "contact", value: mobile }]);
    console.log(data);
    if (mobile.length !== 10) {
      setError("Mobile number is not valid");
    } else {
      if (data.responseCode == 1) {
        setError("");
        setMobileSuccess(false);
        setUserId(data.data.userId);
        setUserType(data.data.userType);
      } else {
        setError(data.e.response.data.responseMessage);
      }
    }
  };

  return (
    <>
      {mobileSuccess ? (
        <div className="loginBox">
          <h1>Login</h1>
          <p>Enter your phone number</p>
          <p className="lightText">We'll send you a message with OTP</p>
          <form onSubmit={handleSubmit}>
            <label>
              Enter mobile no./मोबाइल नंबर डालें।<span>*</span>
            </label>
            <Input
              refData={mobileRef}
              onChange={(e) => setMobile(e.target.value)}
              name="mobile"
              placeholder="Enter mobile no."
              type="number"
              value={mobile}
              required="required"
            />
            {error !== "" ? <p className="error">{error}</p> : ""}
            <button type="button" onClick={handleSubmit}>
              Send OTP
            </button>
          </form>
        </div>
      ) : (
        <OTPBox mobile={mobile} passUserId={userId} passUserType={userType} />
      )}
    </>
  );
}

export default MobileVerify;
