import React from "react";
import PageName from "../../components/PageName/PageName";
import "./askAnExpert.scss";
import { getLanguageId } from "api/Utils";

const AskAnExpert = () => {
  const languageId = localStorage.getItem("languageId");
  return (
    <div className="askAnExpert">
      <PageName
        name={`${languageId != 1 ? "Ask An Expert" : "एक विशेषज्ञ से पूछें"}`}
      />
      <div className="askAnExpert-page">
        <div className="askAnExpert-page__cover">
          <div className="topBox">
            <div className="topBox__left">
              <img
                src={process.env.PUBLIC_URL + "/phone-icon.svg"}
                alt="phone icon"
              />
            </div>
            <div className="topBox__right">
              <p className="txt">ASK An Expert</p>
              <p className="phoneNumber">1800123006141</p>
            </div>
          </div>
          {/* <div className="middleBox">
            <div className="middleBox__left">
              <p className="addressHeading">Corporate Address</p>
              <p>
                <span>Parijat Industries (India) Pvt. Ltd.</span>
                <span>Plot No 239, 3rd Floor,</span>
                <span>Okhla Industrial Estate Phase 3,</span>
                <span>Okhla Industrial Area</span>
                <span>NEW DELHI - 110 020, India.</span>
              </p>
            </div>
            <div className="middleBox__right">
              <p className="addressHeading">Contact Us</p>
              <p>
                <span>Email: contact@parijat.in</span>
                <span>Telephone No.: +91-11-45766000</span>
                <span>
                  Facebook -
                  <a href="https://www.facebook.com/ParijatIndustriesIndiaPvtLimited">
                    https://www.facebook.com/ParijatIndustriesIndiaPvtLimited
                  </a>
                </span>
              </p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default AskAnExpert;
