import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import ProductCard from "../../components/ProductCard/ProductCard";
import "./products.scss";
import { PRODUCTTYPELIST, ProductList } from "api/Constants";
import { APICALL } from "api/ApiCall";
/////
import Sidebar from "../../components/Sidebar/Sidebar";
import PageName from "../../components/PageName/PageName";
import { HOME } from "api/Constants";
import { PRODUCTSUBCATEGORY } from "api/Constants";
import { getLanguageId } from "api/Utils";

const ProductsSubCat = () => {
  const [sideBarData, setSidebarData] = React.useState([]);
  const [ProductListData, setProductListData] = React.useState([]);
  const [nextText, setnextText] = React.useState("");
  const [ headerName, setheaderName ] =  React.useState("");
  const [breadCrumbData, setbreadCrumbData] = React.useState([]);
  
  useEffect(() => {
    fetchProductSubCatList()
    fetchProductList();
    fetchNextText()
  }, []);

  const fetchProductSubCatList = async () => {
    var url_string = window.location.href;
    var url = new URL(url_string);
    var urlParams = url.searchParams.get("id");
    const productTypeId = urlParams[0];
    if(!productTypeId) return alert("Something went wrong")
    const languageId = localStorage.getItem("languageId");
    const productSubCategoryListData = await APICALL(PRODUCTSUBCATEGORY, [
        { name: "productTypeId", value: String(productTypeId) },
        { name: "LanguageId", value: getLanguageId() },
      ]);
    if(!productSubCategoryListData.data) return alert("No Data Found");
      let editData = productSubCategoryListData.data && productSubCategoryListData.data.map((e) => {
        let dt = {
          ...e,
          name: e.subCategoryName,
          image: e.ImgPath,
          id: e.subCategoryId,
          };
        return dt;
      });
    setProductListData(editData);
    setbreadCrumbData(productSubCategoryListData.breadcrumbs);
  };

  const fetchProductList = async () => {
    const languageId = localStorage.getItem("languageId");
    const productListData = await APICALL(PRODUCTTYPELIST, [
      { name: "languageId", value: getLanguageId() },
    ]);
    if(!productListData.data) return alert("No Data Found");
    let customizeProducts = productListData &&  productListData.data.map((e) => {
      let dt = {
        ...e,
        name: e.ProductTypeName,
        image: e.ImgPath,
        id: e.ProductTypeId,
      };
      return dt;
    });
    setSidebarData(customizeProducts);
    //////////
    var url_string = window.location.href;
    var url = new URL(url_string);
    var urlParams = url.searchParams.get("id");
    const productTypeId = urlParams[0];
    if(!productTypeId) return alert("Something went wrong")
    setheaderName(customizeProducts[productTypeId-1].ProductTypeName)
  };

  const fetchNextText = async () => {
    const languageId = localStorage.getItem("languageId");
    const dashboardData = await APICALL(HOME, [
      { name: "languageId", value: getLanguageId() },
    ]);
    setnextText(dashboardData.Next)
  };

  const handlerItemClick= (data,sideBarItem=false)=>{
    console.log("ProductsSubCat....",data, sideBarItem)
    var url_string = window.location.href;
    var url = new URL(url_string);
    var urlParams = url.searchParams.get("id");
    const productTypeId = urlParams[0];
    const { subCategoryId=null, isSubChildCategory=0 } = data;
    //if(isSubChildCategory == 1){
    window.location.href = `/ProductSubChildCategory?id=${productTypeId}_${subCategoryId}`;
    //}else{
    //  window.location.href = `/ProductSubChildCategory?id=${productTypeId}_${0}`;
   // }
  }

  const handlerAllItemClick = (type) =>{
    window.location.href = `/AllProducts`;
  }
  
  return (
    <div>
      <PageName
        name="Products"
        data={breadCrumbData}
        type={1}
        //selectedBread={(data) => this.goToBread(data)}
      />
      <div className="productsPage">
        <Sidebar type={1} />
        <div className="productsList">
          <div className="titleName">{headerName}</div>
          <div className="allProductsCards">
            {ProductListData.map((e, index) => (
              <ProductCard
                key={index}
                data={e}
                handleClick={(data) => handlerItemClick(data)}
                nextText={nextText}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsSubCat;
