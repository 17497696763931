import React, { useState } from "react";
import MobileVerify from "../../components/MobileVerify/MobileVerify";
import Registration from "../../components/Registration/Registration";
import TermOfUse from "../../components/TermOfUse/TermOfUse";
import "./login.scss";

export default function Login() {
  const [toggleLogTerm, setToggleLogTerm] = useState(true);

  const changeEvent = () => {
    setToggleLogTerm(!toggleLogTerm);
  };

  return (
    <div className="login-page">
      <div className="login-page__left">
        <img
          src={process.env.PUBLIC_URL + "/PARIJAT-LOGO_w.png"}
          alt="parijat logo"
        />
      </div>
      <div className="login-page__right">
        <div className="contentBox">
          {/* <Registration />*/}
          {toggleLogTerm ? (
            <TermOfUse
              toggleLogTerm={toggleLogTerm}
              changeEvent={changeEvent}
            />
          ) : (
            <MobileVerify />
          )}
        </div>
      </div>
    </div>
  );
}
