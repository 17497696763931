import React, { useState, useEffect } from "react";
import CustomSelect from "../../ui-components/Select/Select";
import "./cropSelection.scss";
import { APICALL } from "api/ApiCall";
import { CROPNAME, STATELIST } from "api/Constants";
import CustomInput from "../../ui-components/Input/Input";
import { toast } from "react-toastify";

export default function CropSelection({
  language,
  addNewRow,
  cropDetails,
  deteteRow,
  isSubmit,
  changeCropCatID,
  changeCropID,
  changeRakbaValue,
  cropOptions,
  isDisabled,
  zeroValue,
}) {
  const [cropName, setCropName] = useState([]);
  const [acreage, setAcreage] = useState([]);
  const [getcropCat, setgetcropCat] = useState([]);
  const [getcrop, setgetcrop] = useState([]);
  const [cropDetails1, setcropDetails1] = useState([]);
  //////// chnage 1
  // const onChangeCropCatID = async (e, itemId) => {
  //   getCrop(e, itemId);

  // };

  const onChangeCropID = (e, id) => {
    changeCropID(e, id);
  };

  const onInputHandler = (val, id) => {
    if (val > 0 || val == "") {
      changeRakbaValue(val, id);
    } else {
      toast.warn("Invalid value is not allowed");
    }
  };

  useEffect(() => {
    getCropCat();
  }, []);

  useEffect(() => {
    setcropDetails1(cropDetails);
  }, [cropDetails]);

  const getCropCat = async () => {
    const stateData = await APICALL(STATELIST, []);
    const cropCategoryOptions = stateData.cropCategory.map((e) => ({
      label: `${e.cropCategory}`,
      value: `${e.cropCategoryId}`,
    }));
    setgetcropCat(cropCategoryOptions);
  };

  const onChangeCropCatID = async (selectedCat, itemId) => {
    const cropNameData = await APICALL(CROPNAME, [
      {
        name: "cropCategoryId",
        value: selectedCat.value,
      },
    ]);
    const data = cropNameData.data;
    console.log("data from APi--crop name data", data);
    const options = data.map((d) => ({
      value: d.cropId,
      label: d.crop,
    }));
    setgetcrop(options);
    changeCropCatID(selectedCat, itemId, options);
  };

  return (
    <>
      {cropDetails1.map((val, idx) => {
        return (
          <>
            {val.status != "0" ? (
              <div className="cropBoxCoverInner" key={idx}>
                <div className="cropBox">
                  <img
                    style={{ pointerEvents: isDisabled ? "none" : "" }}
                    src={process.env.PUBLIC_URL + "/minusIcon.svg"}
                    alt="minus icon"
                    onClick={() => deteteRow(val.id)}
                  />
                  <div className="form-group">
                    <label>Crop Category/फसल श्रेणी*</label>
                    <CustomSelect
                      options={getcropCat}
                      placeholder="Select"
                      name="cropCategory"
                      data-id={idx}
                      isDisabled={isDisabled}
                      //id={cropCategory}
                      onChange={(e) => onChangeCropCatID(e, val.id)}
                      value={val.cropCategory}
                    />
                    {isSubmit && val.cropCategory == "" && (
                      <p className="error">Select Crop Category</p>
                    )}
                  </div>

                  <div className="form-group">
                    <label>Crop/फसल*</label>
                    <CustomSelect
                      options={val.cropOptions}
                      placeholder="Select"
                      name="crop"
                      data-id={idx}
                      isDisabled={isDisabled}
                      //id={crop}
                      onChange={(e) => onChangeCropID(e, val.id)}
                      value={val.crop}
                    />
                    {isSubmit && val.crop == "" && (
                      <p className="error">Select Crop</p>
                    )}
                  </div>
                  <img
                    style={{ pointerEvents: isDisabled ? "none" : "" }}
                    src={process.env.PUBLIC_URL + "/plusIcon.svg"}
                    alt="plus icon"
                    onClick={addNewRow}
                  />
                </div>
                <div className="form-group inputTopMar">
                  <label>Acreage/एकड़*</label>
                  <CustomInput
                    id={`acreage${idx}`}
                    type="number"
                    placeholder=""
                    name={`acreage${idx}`}
                    isDisabled={isDisabled}
                    onChange={(e) => onInputHandler(e.target.value, val.id)}
                    value={val.acre}
                  />
                  {isSubmit && val.acre == "" && (
                    <p className="error">Acreage/एकड़ can't be blank</p>
                  )}
                  {isSubmit && val.acre <= 0 && val.acre != "" && (
                    <p className="error">Acreage/एकड़ can't be zero</p>
                  )}
                </div>
              </div>
            ) : null}
          </>
        );
      })}
    </>
  );
}
