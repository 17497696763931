import React, { useState, useEffect } from "react";
import PageName from "../../components/PageName/PageName";
import Table from "../../components/Table/Table";
import "./productDetails.scss";
import { APICALL } from "api/ApiCall";
import { ProductDescription, Breadcrumbs } from "api/Constants";
import ReactHtmlParser from "react-html-parser";

// modal
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { getLanguageId } from "api/Utils";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1, 1, 1),
  },
}));

const ProductDetails = (props) => {
  const [itemDetails, setItemDetails] = useState([]);
  const [pathData, setpathData] = useState([]);
  const languageId = localStorage.getItem("languageId");
  const [breadCrumbData, setbreadCrumbData] = React.useState([]);
  const [productName, setProductName] = useState("");
  const [type, setType] = useState(1);

  //modal
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openAudio, setOpenAudio] = React.useState(false);

  const handleOpenModal = () => {
    setOpenAudio(true);
  };

  const handleCloseModal = () => {
    setOpenAudio(false);
  };

  const productInfo = {
    icon: "/headPhoneIcon.svg",
    PdfIcon: "/pdf.svg",
  };

  var url_string = window.location.href;
  var url = new URL(url_string);
  var c = url.searchParams.get("id");
  let itemvalues = c.split("_");
  const id = itemvalues[0];
  const subCatg = itemvalues[1] ? itemvalues[1] : 1;

  useEffect(async () => {
    var urlType = url.searchParams.get("type");
    setType(urlType ? urlType : 1);
    console.log("Type---------", urlType);

    breadcrumbsData();
    let getpathData = JSON.parse(localStorage.getItem("pathData"));
    setpathData(getpathData);
    window.scrollTo(0, 0);
    const productListData = await APICALL(
      ProductDescription,
      [
        { name: "languageId", value: getLanguageId() },
        { name: "productId", value: id },
      ],
      [itemDetails]
    );
    setItemDetails(productListData.data);
    setProductName(productListData.data.breadcrumbs[0].Product);
  }, []);

  const breadcrumbsData = async () => {
    var url_string = window.location.href;
    var url = new URL(url_string);
    var c = url.searchParams.get("id");
    let itemvalues = c.split("_");
    const id = itemvalues[0];
    const propsData = props.location.state;
    const {
      CropId = "",
      StageId = "",
      mpdId = "",
      ProductTypeId = "",
      subCategoryId = "",
    } = propsData[0];
    console.log("sfsafsdfasdfsadfsadf", propsData[0]);
    const breadcrumbsDataList = await APICALL(Breadcrumbs, [
      { name: "languageId", value: getLanguageId() },
      { name: "sourceId", value: `${CropId != "" ? 2 : 1}` },
      { name: "cropId", value: CropId },
      { name: "stageId", value: StageId },
      { name: "majorPestId", value: mpdId },
      { name: "productTypeId", value: ProductTypeId },
      { name: "subCategoryId", value: subCategoryId },
      { name: "productId", value: id },
    ]);
    console.log("bread crumb data list", breadcrumbsDataList);
    setbreadCrumbData(breadcrumbsDataList.data);
  };

  console.log(itemDetails);

  return (
    <>
      {itemDetails.ProductDetail && itemDetails.ProductDetail[0] && (
        <>
          <PageName
            name="Product Details"
            data={breadCrumbData}
            type={type}
            productName={productName}
          />
          <div className="ProductDetails-page">
            <div className="productInfo">
              <div className="productInfo__left">
{console.log(itemDetails.ProductDetail)}
                {itemDetails.ProductDetail[0].podCastPath != "NULL" && (
                  <img
                    src={productInfo.icon}
                    className="headPhoneIcon"
                    alt="head phone icon"
                    onClick={
                      itemDetails.ProductDetail[0].podCastPath != "NULL"
                        ? handleOpenModal
                        : null
                    }
                  />
                )}

                <img
                  src={itemDetails.ProductDetail[0].ImgPath}
                  alt={productInfo.Product}
                />
                <p>{itemDetails.ProductDetail[0].Product}</p>
                {itemDetails.ProductDetail[0].Tagline && (
                  <p>{itemDetails.ProductDetail[0].Tagline}</p>
                )}
                {itemDetails.ProductDetail[0].leafletPath != "NULL" && (
                  <img
                    src={productInfo.PdfIcon}
                    className="pdfIcon"
                    alt="pdf icon"
                    onClick={handleOpen}
                  />
                )}
              </div>
              <div className="productInfo__right">
                <div className="detailsBox">
                  {subCatg == 0 ? (
                    <>
                      <p>{itemDetails.Label[0]["Technical Present"]}</p>
                      <p>
                        {ReactHtmlParser(
                          itemDetails.ProductDetail[0].TechnicalPresent
                        )}
                      </p>{" "}
                    </>
                  ) : (
                    <>
                      <p>{itemDetails.Label[0]["Product Brif"]}</p>
                      <p>
                        {ReactHtmlParser(
                          itemDetails.ProductDetail[0].TechnicalPresent
                        )}
                      </p>{" "}
                    </>
                  )}
                </div>
                <div className="detailsBox">
                  <p>{itemDetails.Label[0]["Product Feature"]}</p>
                  {ReactHtmlParser(
                    itemDetails.ProductDetail[0].ProductFeatures
                  )}
                </div>
                <div className="detailsBox">
                  <p>{itemDetails.Label[0]["Time of application"]}</p>
                  {ReactHtmlParser(
                    itemDetails.ProductDetail[0].TimeOfApplication
                  )}
                </div>
                <div className="detailsBox">
                  <p>{itemDetails.Label[0]["Directions of Use"]}</p>
                  {ReactHtmlParser(itemDetails.ProductDetail[0].DirectionOfUse)}
                </div>
                <div className="detailsBox">
                  <p>{itemDetails.Label[0]["Mode of Action"]}</p>
                  {ReactHtmlParser(itemDetails.ProductDetail[0].ModeOfAction)}
                </div>
                <div className="detailsBox">
                  <p>{itemDetails.Label[0]["Pack Size"]}</p>
                  <p>{itemDetails.ProductDetail[0].PackSize}</p>
                </div>
              </div>
            </div>
            <div className="tableDetails">
              <p className="tableTitle">
                {itemDetails.Label[0].Recommendation}
              </p>
              <div className="table-responsive">
                <Table data={itemDetails} />
              </div>
            </div>
          </div>
          {/* modal */}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openAudio}
            onClose={handleCloseModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <div className="modalCover">
              <span className="modalCloseButton" onClick={handleCloseModal}>
                X
              </span>
              <div className={classes.paper}>
                <audio controls>
                  <source src={`${itemDetails.ProductDetail[0].podCastPath}`} />
                </audio>
              </div>
            </div>
          </Modal>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <div className="modalCover">
              <span className="modalCloseButton" onClick={handleClose}>
                X
              </span>
              <div className={classes.paper}>
                <iframe
                  src={`${itemDetails.ProductDetail[0].leafletPath}`}
                  style={{ width: "80vw", height: "80vh" }}
                ></iframe>
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export default ProductDetails;
