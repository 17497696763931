import React from "react";
import "./input.scss";

export default function Input({
  name,
  type,
  placeholder,
  onChange,
  value,
  required,
  pattern,
  id,
  isDisabled,
  refData,
}) {
  return (
    <input
      ref={refData != null ? refData : null}
      name={name}
      type={type}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      required={required}
      pattern={pattern}
      id={id}
      disabled={isDisabled}
    />
  );
}
