import React from "react";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import "./pageName.scss";

const PageName = (props) => {
  const languageId = localStorage.getItem("languageId");
  let convertLanVal = languageId.replace(/'/g, " ").trim();
  const {
    name = null,
    displayHome = true,
    data = [],
    type,
    productName = "",
  } = props;
  const {
    ProductTypeId = "",
    productCategory = "",
    subCategoryId = 0,
    productSubCategory = "",
    CropId = "",
    Crop = "",
    StageId = "",
    Stage = "",
    mpdId = "",
    MajorPest = "",
  } = data && data[0] ? data[0] : [];

  console.log("-----", data);
  const onSelectHandler = (type, page) => {
    //return
    var url_string = window.location.href;
    var url = new URL(url_string);
    let pathName = url.pathname;
    if (type == 2) {
      if (page == 1) {
        if (pathName != "/Crops") window.location.href = `/Crops`;
      }
      if (page == 2) {
        if (pathName != "/CropStage")
          window.location.href = `/CropStage?id=${CropId}`;
      }
      if (page == 3) {
        if (pathName != "/MajorPestDeficiencies")
          window.location.href = `/MajorPestDeficiencies?id=${CropId}_${StageId}`;
      }
      if (page == 4) {
        if (pathName != "/SymptomsAndSolutions")
          window.location.href = `/SymptomsAndSolutions?id=${CropId}_${StageId}_${mpdId}`;
      }
    } else if (type == 1) {
      console.log(page);
      if (page == 1) {
        if (pathName != "/AllProducts") window.location.href = `/AllProducts`;
      }
      if (page == 2) {
        let subCategoryIdData = subCategoryId == 0 || !subCategoryId ? 0 : 1;
        if (subCategoryIdData == 0) {
          if (pathName != "/ProductSubChildCategory")
            window.location.href = `/ProductSubChildCategory?id=${ProductTypeId}_${subCategoryIdData}`;
        } else {
          if (pathName != "/ProductsSubCat")
            window.location.href = `/ProductsSubCat?id=${ProductTypeId}`;
        }
      }
      if (page == 3) {
        if (pathName != "/ProductSubChildCategory")
          window.location.href = `/ProductSubChildCategory?id=${ProductTypeId}_${subCategoryId}`;
      }
    }
  };

  return (
    <div className="pageName" style={{ display: "flex", flexDirection: "row" }}>
      <Breadcrumbs className="breadCrumb" aria-label="breadcrumb">
        {displayHome && (
          <Link className="breadCrumbLink" color="inherit" href="/Home">
            {convertLanVal == 1 ? "होम" : "Home"}
            {/* Home */}
          </Link>
        )}
        {!type && name && (
          <Link className="breadCrumbLink" color="inherit" href={void 0}>
            {name}
          </Link>
        )}
        {type == 1 && (
          <Link
            className="breadCrumbLink"
            color="inherit"
            onClick={() => onSelectHandler(1, 1)}
            href={void 0}
          >
            {convertLanVal == 1 ? "उत्पादों" : "Products"}
            {/* Products */}
          </Link>
        )}
        {type == 1 && productCategory != "" && (
          <Link
            className="breadCrumbLink"
            color="inherit"
            onClick={() => onSelectHandler(1, 2)}
            href={void 0}
          >
            {productCategory}
          </Link>
        )}
        {type == 1 && productSubCategory != "" && productSubCategory && (
          <Link
            className="breadCrumbLink"
            color="inherit"
            onClick={() => onSelectHandler(1, 3)}
            href={void 0}
          >
            {productSubCategory}
          </Link>
        )}
        {type == 1 && productName != "" && (
          <Link className="breadCrumbLink" color="inherit" href={void 0}>
            {productName}
          </Link>
        )}
        {type == 2 && (
          <Link
            className="breadCrumbLink"
            color="inherit"
            onClick={() => onSelectHandler(2, 1)}
            href={void 0}
          >
            {convertLanVal == 1 ? "फसलें" : "Crops"}
            {/* Crops */}
          </Link>
        )}
        {type == 2 && Crop != "" && (
          <Link
            className="breadCrumbLink"
            color="inherit"
            onClick={() => onSelectHandler(2, 2)}
            href={void 0}
          >
            {Crop}
          </Link>
        )}
        {type == 2 && Stage != "" && (
          <Link
            className="breadCrumbLink"
            color="inherit"
            onClick={() => onSelectHandler(2, 3)}
            href={void 0}
          >
            {Stage}
          </Link>
        )}
        {type == 2 && MajorPest != "" && (
          <Link
            className="breadCrumbLink"
            color="inherit"
            onClick={() => onSelectHandler(2, 4)}
            href={void 0}
          >
            {MajorPest}
          </Link>
        )}
        {type == 2 && productName != "" && (
          <Link className="breadCrumbLink" color="inherit" href={void 0}>
            {productName}
          </Link>
        )}
      </Breadcrumbs>
    </div>
  );
};

export default React.memo(PageName);
