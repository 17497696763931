import axios from "axios";
import React from "react";
import TabsComp from "ui-components/Tabs/Tabs";
import PageName from "../../components/PageName/PageName";
import "./weather.scss";
import { getLanguageId } from "api/Utils";

const Weather = () => {
  const [lat, setlat] = React.useState("");
  const [lng, setlng] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [data, setData] = React.useState([]);
  const [ErrorText, setErrorText] = React.useState("");
  const languageId = localStorage.getItem("languageId");

  React.useEffect(() => {
    weatherApi();
  }, []);

  const weatherApi = () => {
    window.navigator.geolocation.getCurrentPosition(
      (d) => {
        const { coords } = d;
        const { latitude = "", longitude = "" } = coords;
        setlat(latitude);
        setlng(longitude);
        getAPIData(latitude, longitude);
        getAddressByLatLog(latitude, longitude);
      },
      () => setErrorText("Please allow your location to see weather.")
    );
  };

  const getAPIData = (lat = "", lng = "") => {
    let payload = {
      lat: lat,
      lon: lng,
      appId: "5f4babf7fcb618fe99e8774772a6935e",
      units: "metric",
    };
    let url = `https://api.openweathermap.org/data/2.5/onecall?lat=${lat}&lon=${lng}&appid=5f4babf7fcb618fe99e8774772a6935e&units=metric`;
    axios
      .post(url)
      .then((data) => {
        setData(data); //set api resp data
      })
      .catch((err) => console.log("Api err", err));
  };

  const getAddressByLatLog = async (lat = "", lng = "") => {
    if (lat !== 0 && lng !== 0) {
      fetch(
        "https://maps.googleapis.com/maps/api/geocode/json?address=" +
          lat +
          "," +
          lng +
          "&key=" +
          "AIzaSyAOgmNvw12tP55TLe7tsZAa8nNsRPeoPn4"
      )
        .then((response) => response.json())
        .then((responseJson) => {
          if (
            responseJson.results[0].formatted_address != "" ||
            responseJson.results[0].formatted_address != null
          ) {
            setAddress(responseJson.results[0].formatted_address);
          }
        });
    }
  };

  return (
    <>
      {data.data ? (
        <div className="Weather">
          <PageName
            name={`${languageId != 1 ? "Weather" : "मौसम"}`}
          />
          <div className="Weather-page">
            <div className="Weather-page__cover">
              <div className="left-box">
                <div className="top-box">
                  <p className="top">
                    {data.data.current.weather[0].description}
                  </p>
                  <p>
                    {new Date(data.data.current.dt * 1000).toLocaleDateString(
                      "default",
                      { month: "short" }
                    ) +
                      " " +
                      new Date(data.data.current.dt * 1000).getDate() +
                      " " +
                      new Date(data.data.current.dt * 1000).getFullYear()}
                    {/*Feb. 23rd. Tue*/}
                  </p>
                </div>
                <div className="middle-box">
                  <img
                    src={`http://openweathermap.org/img/wn/${data.data.current.weather[0].icon}@2x.png`}
                    alt="current day cloud image"
                  />
                </div>
                <div className="bottom-box">
                  <p className="top">
                    {Math.round(data.data.current.temp)}
                    <sup>0</sup> C
                  </p>
                  <p className="maxWidthP">
                    Humidity {data.data.current.humidity}%
                  </p>
                </div>
              </div>
              <div className="right-box">
                <div className="top-box">
                  <div className="left-child-box">
                    <img
                      src={process.env.PUBLIC_URL + "/location_icon.svg"}
                      alt="location icon"
                    />
                    <p>{address}</p>
                  </div>
                </div>
                <TabsComp
                  hourlyData={data.data.hourly}
                  dailyData={data.data.daily}
                />
              </div>
            </div>
          </div>
        </div>
      ) : ErrorText != "" ? (
        <div className="locationAllow">{ErrorText}</div>
      ) : null}
    </>
  );
};

export default Weather;
