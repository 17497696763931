import axios from "axios";
import { SERVER_URL } from "../constant/Constant";
import loader from "./displayLoader";

export const APICALL = async (url, data) => {
  let formData = new FormData();
  //Append API Server Key Global Need
  formData.append("apikey", "2992c9debd79d6e9");
  data.forEach((element) => {
    formData.append(element.name, element.value);
  });
  try {
    loader.show();
    const response = await axios
      .post(SERVER_URL + url, formData)
      .finally(() => loader.hide());
    return response.data;
  } catch (e) {
    return { e };
  }
};
