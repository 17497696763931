import React, { useState, useEffect, useRef } from "react";
import Registration from "../Registration/Registration";
import "./otpBox.scss";
import { APICALL } from "api/ApiCall";
import { VERIFYOTP, GETOTP, USERPROFILE } from "api/Constants";

const OTPBox = ({ mobile, passUserId, passUserType }) => {
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [successOtp, setSuccessOtp] = useState(true);
  const [seconds, setSeconds] = useState(120);
  const [error, setError] = useState("");
  const inputRef = useRef([]);

  useEffect(() => {
    inputRef.current[0].focus();
  }, []);
  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    }
  }, [seconds]);

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;
    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const verifyOtp = async (e) => {
    e.preventDefault();
    const enteredOtp = otp.join("");
    const data = await APICALL(VERIFYOTP, [
      { name: "userId", value: passUserId },
      { name: "otp", value: enteredOtp },
    ]);
    if (data.responseCode === "1" && passUserType == "0") {
      setSuccessOtp(false);
    } else if (data.responseCode === "1" && passUserType == "1") {
      localStorage.setItem("loginStatus", true);
      localStorage.setItem("userId", passUserId);
      const responseData = await APICALL(USERPROFILE, [
        {
          name: "userId",
          value: passUserId,
        },
      ]);
      const language = responseData.data.userDetail[0].LanguageId;
      localStorage.setItem("languageId", language);
      window.location.reload();
    } else {
      if (enteredOtp == "") {
        setError(data.e.response.data.responseMessage);
      } else {
        console.log(data);
        setError(data.responseMessage);
      }
    }
  };

  const resendOtp = async () => {
    const data = await APICALL(GETOTP, [{ name: "contact", value: mobile }]);
    setError("");
    setOtp(new Array(6).fill(""));
    setSeconds(120);
  };

  return (
    <>
      {successOtp ? (
        <div className="OTPBox">
          <div className="otpHeading">
            <h1>Verify Details</h1>
            <p>OTP sent to {mobile}</p>
          </div>
          <form className="otp-form" onSubmit={verifyOtp}>
            <div className="otpInputs">
              <label>Enter OTP</label>
              <p>We have sent a OTP on your number</p>
              {otp.map((data, index) => {
                return (
                  <input
                    ref={(data) => (inputRef.current[index] = data)}
                    className="otp-field"
                    type="text"
                    name="otp"
                    maxLength="1"
                    key={index}
                    value={data}
                    onChange={(e) => handleChange(e.target, index)}
                    onFocus={(e) => e.target.select()}
                  />
                );
              })}
            </div>

            {error !== "" ? <p className="error">{error}</p> : ""}
            {seconds > 0 ? (
              <p className="resendMsg">
                OTP resend link activate in {seconds} seconds
              </p>
            ) : (
              <p className="resendOTP" onClick={() => resendOtp()}>
                Resend OTP
              </p>
            )}
            <div className="btn-cvr">
              <button type="submit">Verify OTP</button>
            </div>
          </form>
        </div>
      ) : (
        <Registration userId={passUserId} />
      )}
    </>
  );
};

export default OTPBox;
