export const GETPDF = "/getPdfFile";
export const GETOTP = "/getOtp";
export const VERIFYOTP = "/verifyOtp";
export const STATELIST = "/getStateList";
export const DISTRICTLIST = "/getDistrictList";
export const LANGLIST = "/getLanguageList";
export const CROPNAME = "/getCropName";
export const USERDETAILS = "/userDetail";
export const USERPROFILE = "/getProfile";
export const EDITPROFILE = "/editProfile";

export const HOME = "/dashboard";
export const PRODUCTTYPELIST = "/getProductTypeList";
export const ProductList = "/getProductLis";
export const PRODUCTSUBCATEGORY = "/getProductSubCategory";
export const ProductSubChildCategory = "/getProductSubChildCategory";

export const CROPLIST = "/getCropList";
export const CropStageList = "/getCropStageList";
export const MajorPestDeficiency = "/getMajorPestDeficiency";
export const SymptomsSolution = "/getSymptomsSolution";

export const ProductDescription = "/getProductDescription";
export const userDetailRaw = "/userDetailRaw";
export const Breadcrumbs = "/getBreadcrumbs";
