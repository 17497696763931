import React, { useState, useEffect, useRef } from "react";
import "./registration.scss";
import CustomInput from "../../ui-components/Input/Input";
import CropSelection from "../CropSelection/CropSelection";
import CustomSelect from "../../ui-components/Select/Select";
import { connect } from "react-redux";
import { login } from "../../redux/action/login.action";
import { APICALL } from "api/ApiCall";
import { toast } from "react-toastify";
import {
  STATELIST,
  DISTRICTLIST,
  LANGLIST,
  USERDETAILS,
  userDetailRaw,
} from "api/Constants";
import Select from "react-select";
//import "../select.scss";
import "../../ui-components/Select/select.scss";
import axios from "axios";
import { SERVER_URL } from "../../constant/Constant";

function Registration(props) {
  const [cropDetails, setCropDetails] = useState([
    {
      id: 0,
      cropCategory: "",
      crop: "",
      acre: "",
      cropOptions: [],
    },
  ]);
  const [stateList, setStateList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [langList, setLangList] = useState([]);
  const [cropCategory, setCropCategory] = useState([]);
  const sizeRef = React.useRef();
  const [DistrictValue, setDistrictValue] = useState([]);
  const [apiCropDetail, setApiCropDetail] = useState([
    {
      acre: "",
      cropCategoryListId: "",
      cropId: "",
    },
  ]);
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  useEffect(async () => {
    const stateData = await APICALL(STATELIST, []);
    const options = stateData.data.map((e) => ({
      label: `${e.StateName}`,
      value: `${e.StateId}`,
    }));
    setStateList(options);
    const langData = await APICALL(LANGLIST, []);
    const langOptions = langData.data.map((e) => ({
      label: `${e.LanguageName}`,
      value: `${e.LanguageId}`,
    }));
    setLangList(langOptions);
  }, []);

  const [isSubmit, setIsSubmit] = useState(false);

  const updateSelectedData = (data) => {
    console.log(data);
    // setSelectedData()
  };

  const [values, setValues] = useState({
    name: "",
    state: "",
    district: "",
    language: "",
  });

  const [errors, setErrors] = useState({});
  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    var letters = /^[a-zA-Z\s]*$/;
    let matched = value.match(letters);
    if (value == "" || (matched && value.length <= 50)) {
      setValues({
        ...values,
        [name]: value,
      });
    } else {
      toast.warn("Number and Special Character are not allowed");
    }
  };

  const handleChangeSelect = async (e = "", type) => {
    if (type == "state") {
      setValues({
        ...values,
        state: e,
      });
      const districtData = await APICALL(DISTRICTLIST, [
        { name: "stateId", value: e },
      ]);
      const data = districtData.data;
      const options = data.map((d) => ({
        value: d.districtId,
        label: d.district,
      }));
      setDistrictList(options);
    } else if (type === "district") {
      setDistrictValue(e);
    } else if (type === "language") {
      setValues({
        ...values,
        language: e,
      });
    }
  };

  const validateInfo = (e) => {
    e.preventDefault();
    let errors = {};
    if (!values.name.trim()) {
      errors.name = "Enter your name";
    }

    if (!values.state || values.state == "Select") {
      errors.state = "Please select a State";
    }

    if (DistrictValue == "") {
      errors.district = "Please select a District";
    }

    if (!values.language) {
      errors.language = "Please select a Language";
    }
    setIsSubmit(true);
    if (Object.keys(errors).length === 0) {
      setErrors({});
      handleSubmit();
    } else {
      setErrors(errors);
    }
  };

  const handleSubmit = async (e) => {
    const { language = "", name = "", state = "" } = values;
    const districtValue = DistrictValue;
    let payloadcropData = [];
    cropDetails.map((e) => {
      let dt = {
        acre: e.acre,
        cropCategoryListId: e.cropCategory.value,
        cropId: e.crop.value,
      };
      payloadcropData.push(dt);
    });
    let acreStatus = false;
    let cropStatus = false;
    let cropCategoryListId = false;
    payloadcropData.map((e) => {
      if (e.acre == "" || e.acre <= 0) {
        acreStatus = true;
      }
      if (!e.cropId) {
        cropStatus = true;
      }
      if (!e.cropCategoryListId) {
        cropCategoryListId = true;
      }
    });
    if (cropCategoryListId || cropStatus || acreStatus) {
      return;
    }
    let payload1 = {
      apikey: "2992c9debd79d6e9",
      userId: props.userId ? String(props.userId) : "42",
      name: name,
      languageId: language,
      stateId: state,
      districtId: districtValue,
      cropdetail: payloadcropData,
    };
    let payload = JSON.stringify(payload1);
    console.log("payload.....", payload);
    localStorage.setItem("userId", payload1.userId);
    axios
      .post(`${SERVER_URL}${userDetailRaw}`, payload)
      .then(({ data }) => {
        console.log("Data.............", data);
        if (data.responseCode == 1) {
          alert("Login Success");
          console.log("================================", language);
          localStorage.setItem("languageId", language);
          localStorage.setItem("loginStatus", true);
          window.location.href = `/Home`;
        } else {
          alert("Something went wrong");
        }
      })
      .catch((e) => alert("Something went wrong"));
  };

  /////////////////////////
  const addNewRow = () => {
    setIsSubmit(false);
    var maxid = 0;

    cropDetails.map(function (obj) {
      if (obj.id > maxid) maxid = obj.id;
    });
    setCropDetails([
      ...cropDetails,
      {
        id: maxid + 1,
        cropCategory: "",
        crop: "",
        acre: "",
      },
    ]);
  };

  const deleteRow = (id) => {
    if (cropDetails.length > 1) {
      setCropDetails(cropDetails.filter((item) => item.id !== id));
    }
  };

  const changeCropCatID = (val, id, options) => {
    console.log("sssssqqq", options);
    let item = cropDetails.filter((item) => item.id == id);
    const {
      id: itemId = "",
      cropCategory = "",
      crop = "",
      acre = "",
    } = item[0];
    let newArray = [...cropDetails];
    newArray[id] = {
      id: itemId,
      cropCategory: val,
      crop: crop,
      acre: acre,
      cropOptions: options,
    };
    console.log("item", newArray[id]);
    setCropDetails(newArray);
  };

  const changeCropID = (val, id) => {
    let item = cropDetails.filter((item) => item.id == id);
    const {
      id: itemId = "",
      cropCategory = "",
      crop = "",
      acre = "",
      cropOptions,
    } = item[0];
    let newArray = [...cropDetails];
    newArray[id] = {
      id: itemId,
      cropCategory: cropCategory,
      crop: val,
      acre: acre,
      cropOptions: cropOptions,
    };
    setCropDetails(newArray);
  };

  const changeRakbaValue = (val, id) => {
    let item = cropDetails.filter((item) => item.id == id);
    const {
      id: itemId = "",
      cropCategory = "",
      crop = "",
      acre = "",
    } = item[0];
    console.log("item", item);
    let newArray = [...cropDetails];
    newArray[id] = {
      id: itemId,
      cropCategory: cropCategory,
      crop: crop,
      acre: val,
    };
    setCropDetails(newArray);
  };
  /////////////////////////////
  return (
    <div className="registration-form">
      <form onSubmit={validateInfo}>
        <h1>Registration</h1>
        <div className="form-group">
          <label>Name/नाम*</label>
          <CustomInput
            refData={inputRef}
            type="text"
            placeholder="Enter name"
            name="name"
            value={values.name}
            onChange={handleChangeInput}
            pattern="\S(.*\S)?"
          />
          {isSubmit && values.name == "" && (
            <span className="error">{errors.name}</span>
          )}
        </div>
        <div className="form-group">
          <label>State/राज्य*</label>
          <CustomSelect
            options={stateList}
            placeholder="Select"
            onChange={(e) => {
              console.log("ppp", e);
              try {
                sizeRef.current.select.setValue("");
                handleChangeSelect("", "district");
              } catch {
                console.log("errr...");
              }
              handleChangeSelect(e.value, "state");
            }}
          />
          {isSubmit && values.state == "" && (
            <span className="error">{errors.state}</span>
          )}
        </div>
        <div className="form-group">
          <label>District/जिला*</label>
          <Select
            ref={sizeRef}
            options={districtList}
            onChange={(e) => handleChangeSelect(e.value, "district")}
            className="react-select-container"
            classNamePrefix="react-select"
            menuPlacement="auto"
            placeholder={"Select"}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: "#00a651",
                primary: "#00a651",
              },
            })}
          />
          {/*
          <CustomSelect
            id="district"
            options={districtList}
            placeholder="Select"
            onChange={(e) => handleChangeSelect(e, "district")}
            // ref={sizeRef}
          /> */}
          {isSubmit && values.district == "" && (
            <span className="error">{errors.district}</span>
          )}
        </div>
        <div className="form-group">
          <label>Language/भाषा*</label>
          <CustomSelect
            options={langList}
            placeholder="Select"
            onChange={(e) => handleChangeSelect(e.value, "language")}
          />
          {isSubmit && values.language == "" && (
            <span className="error">{errors.language}</span>
          )}
        </div>
        <div className="cropBoxCover">
          <CropSelection
            isSubmit={isSubmit}
            updateSelectedData={updateSelectedData}
            addNewRow={addNewRow}
            cropDetails={cropDetails}
            deteteRow={deleteRow}
            changeCropID={changeCropID}
            changeCropCatID={changeCropCatID}
            changeRakbaValue={changeRakbaValue}
          />
        </div>
        <div className="btn-cvr">
          <button type="submit">Submit</button>
        </div>
      </form>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  login: (isLogin) => dispatch(login(isLogin)),
});

export default connect(null, mapDispatchToProps)(Registration);
