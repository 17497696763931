import React, { useState, useEffect } from "react";
import "./footer.scss";
import CustomSelect from "../../ui-components/Select/Select";
import { APICALL } from "api/ApiCall";
import { LANGLIST } from "api/Constants";

const Footer = () => {
  const [langList, setLangList] = useState([]);
  useEffect(async () => {
    const langData = await APICALL(LANGLIST, []);
    const langOptions = langData.data.map((e) => ({
      label: `${e.LanguageName}`,
      value: `${e.LanguageId}`,
    }));
    setLangList(langOptions);
  }, []);
  const goToPDF = (id) => {
    console.log("id", id);
    if (id == 0) {
      window.location.href = `/Pdf?Term_of_use`;
    }
    if (id == 2) {
      window.location.href = `/Pdf?Privacy_Policy`;
    }
    if (id == 3) {
      window.location.href = `/Pdf?Safety`;
    }
    if (id == 4) {
      alert("Language");
    }
  };

  const handleChangeSelect = (e) => {
    localStorage.setItem("languageId", e);
    window.location.reload();
  };
  return (
    <footer>
      <p>© 2019 Parijat Industries (India) Pvt. Ltd. All Right Reserved.</p>
      <div className="footerLink">
        <span onClick={() => goToPDF(0)}>Term of use</span> /{" "}
        <span onClick={() => goToPDF(2)}>Privacy Policy</span> /{" "}
        <span onClick={() => goToPDF(3)}>Safety</span> /{" "}
        <div className="footerChangeLang">
          <CustomSelect
            menuPlacementText={true}
            options={langList}
            placeholder="Change Language"
            onChange={(e) => handleChangeSelect(e.value)}
          />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
