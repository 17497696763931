import React, { Component } from 'react';
import './notFound.css';

export default class NotFound extends Component {
  render() {
    return (
      <div className="notFound">
        <h1>404</h1>
        <p className="firstP">Look like you're lost </p>
        <p> the page you are looking for not avaible!</p>
        <button onClick={()=> this.props.history.push('/Home')  }>Go to Home</button>
      </div>
    );
  }
}
