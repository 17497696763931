import React from "react";
import ReactDOM from "react-dom";
// import "core-js/es6/map";
// import "core-js/es6/set";
// import "core-js/fn/array/find";
// import "core-js/fn/array/includes";
// import "core-js/fn/number/is-nan";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
