import React, { useState, useEffect } from "react";
import RadioGroup from "ui-components/RadioGroup/RadioGroup";
import "./termOfUse.scss";
//API CAll Method
import { APICALL } from "api/ApiCall";
// API Url Constants
import { GETPDF } from "api/Constants";

export default function TermOfUse({ changeEvent, toggleLogTerm }) {
  const [error, setError] = useState("");
  const items = [
    {
      title: "Agree",
      id: "1",
    }
  ];

  const [pdfDataLink, setPdfData] = useState("");

  useEffect(async () => {
    const pdfData = await APICALL(GETPDF, []);
    setPdfData(pdfData.data[0].ImgPath);
  }, []);

  const [value, setValue] = useState(null);

  const handleChange = (event) => {
    setValue(event.target.value);
    if (event.target.value === "1") {
      changeEvent(!toggleLogTerm);
    } else {
      setError(`You can't go forward without select agree`);
    }
  };

  return (
    <div className="termOfUse">
      <div className="totalE-logo">
        <img src={process.env.PUBLIC_URL + "/header_bg.png"} alt="" />
      </div>
      <div className="termOfUse__text">
        {/* <object
          data={pdfDataLink}
          type="application/pdf"
          width="95%"
          height="100%"
        ></object> */}
        <h2>TERMS OF USE</h2>
        <p>
          Welcome to Parijat Industries (India) Pvt. Ltd. (hereinafter referred
          to as Parijat)! <br /> <br />
          To ensure a harmonious and fruitful relationship between us and our
          App users we are providing the terms and policies that shall govern
          the usage of our App. <br /> <br />
          As we progress, we get bigger and better and consequently these
          policies may change as per the requirements, so please refer to them
          periodically. Our Terms of Use and Privacy Policy may change at any
          time without prior notification and along with this the App may also
          update as it is developed further. <br /> <br />
          By accessing this App, you are agreeing to be bound by these App’s
          Terms of Use, all applicable laws and regulations, and agree that you
          are responsible for compliance with any applicable local laws. If you
          do not agree with any of these terms, you are prohibited from using or
          accessing this App. The materials contained in this App are protected
          under applicable laws for e.g. copyright, trademarks, etc. <br />
          <br />
          These Terms of Use (“Terms”) along with Privacy Policy (“Privacy
          Policy”) forms a legally binding agreement (“Agreement”) between the
          App users and us. Hence, we insist that users must spend time reading
          these Terms and Privacy Policy and let us know at contact@pariajt.in,
          if the users have any queries regarding the same. We will try our best
          to answer the queries.
        </p>
        <h3>YOUR APPROVAL</h3>
        <ol className="numberList">
          <li>
            You approve and accept this agreement by:
            <ol>
              <li>accessing the App.</li>
            </ol>
          </li>
          <li>
            You can accept this agreement only if
            <ol>
              <li>You are above 18 years of age,</li>
              <li>
                You are eligible and mentally capable to execute a binding
                contract with Parijat pursuant to accessing the App; and
              </li>
              <li>You are not legally barred from accessing the App</li>
            </ol>
          </li>
          <li>
            You understand that we want you to not access the App if you do not
            understand, approve of or accept all the terms specified in this
            Agreement. Hence, you are requested to read these Terms and Privacy
            Policy (hyperlinked) carefully and understand the agreement before
            you accept it and agree to be bound by it.
          </li>
        </ol>
        <h3>USAGE</h3>
        <ol className="numberList">
          <li>
            Parijat grants you a limited license to access this App and not to
            modify, amend or alter it or any portion of it.
          </li>
          <li>
            In order to provide access of the App to you, we may request you to
            register and/or provide and /or confirm information about yourself.
            We trust that any information provided by you will always be true,
            accurate, complete and updated
          </li>
          <li>
            The content displayed on the App is for your non-commercial,
            non-legal and personal use. You are not allowed to copy, reproduce,
            alter, modify, create derivative works of, or publicly display any
            content displayed on this App.
          </li>
          <li>
            We may stop provision of the App (or any part of it), permanently or
            temporarily, to you or to users generally or may modify or change
            the nature of the App and/or these terms at our sole discretion,
            without any prior notice to you. Your use of the App following any
            such modification constitutes your deemed acceptance to be bound by
            the Agreement (or as it may be modified).
          </li>
        </ol>
        <h3>RESTRICTIONS ON YOUR USE</h3>
        <ol className="numberList">
          <li>
            You will not use the App, or any content provided thereof for any
            purpose that is illegal, unlawful or prohibited by this Agreement.
          </li>
          <li>
            You will not use the App, or any content provided thereof for any
            legal purpose.
          </li>
          <li>
            You will not access (or attempt to access) the content provided
            through the App by any other medium than through the App, unless you
            have been specifically allowed to do so in a separate written
            agreement with Parijat.
          </li>
          <li>
            You will not redistribute, sublicense, rent, publish, sell, assign,
            lease, market, transfer, or otherwise make the App or any component
            or content thereof, available to third parties
          </li>
          <li>
            You will not circumvent or disable any digital rights management,
            usage rules, or other security features of the App; remove, alter,
            or obscure any proprietary notices (including copyright notices) on
            any portion of the App; and not use the App in a manner that
            threatens the integrity, performance, or availability of the App
          </li>
          <li>
            You will not attempt to or engage in any activity that may:
            <ol>
              <li>
                collect information about other users of the App for any illegal
                or unlawful purpose;
              </li>
              <li>
                create any user accounts by automated means or under false or
                fraudulent pretenses for using the App;
              </li>
              <li>
                transmit any viruses, worms, defects, trojan horses, or any
                items of a destructive nature through the App;
              </li>
              <li>
                use the App in any manner that could damage, disable,
                overburden, or impair, or undertake any action which is harmful
                or potentially harmful to, any of the servers, networks,
                computer systems or resources connected to any of the servers
                connected, directly or indirectly to the App, or interfere with
                any other third party's use and enjoyment of the App;
              </li>
              <li>
                forge headers or otherwise manipulate identifiers in order to
                disguise the origin of any content transmitted through the App;
                or
              </li>
              <li>
                obtain any materials or information through any means not
                intentionally made available, in the opinion of Parijat, through
                the App.
              </li>
            </ol>
          </li>
          <li>
            You will not impersonate another person or impersonate, guide or
            host on behalf of, or falsely state or otherwise misrepresent your
            affiliation with any person or entity, including, but not limited to
            our officials, employees, agents, partners, affiliates, dealers and
            franchisees.
          </li>
        </ol>
        <h3>TERMINATION</h3>
        <ol className="numberList">
          <li>
            Your access to the App may be terminated if you knowingly or
            unknowingly cause direct or indirect breach, as ascertained by
            Parijat, of these Terms or Privacy Policy as a whole or in part;{" "}
          </li>
          <li>
            We may have to terminate your access to the App if
            <ol>
              <li>
                We are required to do so by any law (for example, where the
                access to and/or provision of the App to you becomes unlawful);
              </li>
              <li>
                {" "}
                The third party, if any, with whom we offered the App to you has
                terminated its relationship with us or ceased to offer the
                related services to us or to you;
              </li>
              <li>
                The provision of App to you, is no longer viable or feasible for
                us; or
              </li>
              <li>You are a repeat infringer of this agreement.</li>
            </ol>
          </li>
          <li>
            We may terminate this agreement at any time, with or without notice
            and may procure disabling your access to the App and/or barring you
            from any future access to the App.
          </li>
          <li>
            When this Agreement comes to an end, all of the legal rights,
            obligations and liabilities that you and Parijat have benefited
            from, been subject to (or which have accrued over time whilst the
            Agreement has been in force) or which are expressed to continue
            indefinitely, shall be unaffected by this cessation, and shall
            continue to apply to such rights, obligations and liabilities
            indefinitely.
          </li>
        </ol>
        <h3>INTELLECTUAL PROPERTY</h3>
        <ol className="numberList">
          <li>
            Your use of the App is and at all times shall be governed by and
            subject to the laws regarding copyright, trademark, patent, and
            trade secret ownership and use of intellectual property. You agree
            to abide by laws regarding copyright, trademark, patent, and trade
            secret ownership and use of intellectual property, and You shall be
            solely responsible for any violations of any laws and for any
            infringements of any intellectual property rights caused by your
            access of the App.
          </li>
          <li>
            Parijat owns and retains all the intellectual property rights to the
            content made available to you through the App.
          </li>
          <li>
            All trademarks, brands and service marks of the App are the property
            of Parijat only. Parijat owns all the copyrights and database in
            relation to the App.
          </li>
          <li>
            The App or any underlying technology or software used in connection
            with the App may contain rights of Parijat or its affiliates or any
            third party. For use of any third party’s intellectual property, you
            may need to get permission directly from the owner of the
            intellectual property.
          </li>
          <li>
            Parijat respects the intellectual property rights of others and does
            not hold any responsibility for any violations of any intellectual
            property rights by you.
          </li>
        </ol>
        <h3>PRIVACY</h3>
        <ol className="numberList">
          <li>
            Our Privacy Policy (hyperlinked) explains how we treat your personal
            data and protect your privacy when you use the App. By accessing the
            App, you agree that we can use such data according to our Privacy
            Policy
          </li>
          <li>
            You are responsible for maintaining the confidentiality of
            passwords, you use to access the App. Accordingly, you are solely
            responsible for all activities that occur with your device. If you
            become aware of any unauthorized use of your device, you will notify
            the relevant authorities as soon as possible
          </li>
        </ol>
        <h3>LINKS</h3>
        <p>
          Parijat has not reviewed all the links linked to its App and is not
          responsible for the contents of any such links. The inclusion of any
          link does not imply endorsement by Parijat of the same. The links are
          not under the control of Parijat and use of the same is at the user's
          own risk.
        </p>
        <h3>LIMITATIONS</h3>
        <p>
          In no event shall Parijat be liable for any damages (including,
          without limitation, damages for loss of data or profit, or due to
          business interruption,) arising out of the use or inability to use the
          materials on Parijat’s App, even if Parijat or a Parijat authorized
          representative has been notified orally or in writing of the
          possibility of such damage.{" "}
        </p>
        <h3>INDEMNIFICATION</h3>
        <p>
          To the extent authorized by law, you agree to indemnify and hold
          harmless Parijat, its employees, officers, directors, affiliates, and
          agents from and against any and all claims, losses, expenses, damages,
          and costs, including reasonable attorneys’ fees, resulting directly or
          indirectly from or arising out of (a) your violation of the Terms, (b)
          your use of any of the services.
        </p>
        <h3>DISCLAIMER</h3>
        <p>
          The materials on Parijat's App is provided "as is" and is provided
          only for information purposes and not fir any other purpose. Parijat
          makes no warranties, expressed or implied, and hereby disclaims and
          negates all other warranties, including without limitation, implied
          warranties or conditions of merchantability, fitness for a purpose, or
          non-infringement of intellectual property or other violation of
          rights. In furtherance Parijat shall not be liable for any misuse or
          data theft because of your use of the App. Parijat also does not
          warrant that the functions contained in the App will be uninterrupted
          or error free, that defects will be corrected, or the servers that
          make them available are free of viruses or other harmful components.
          Also, if the App is not suitable for your device on which you run the
          App, Parijat shall not be responsible for the same.
        </p>
        <h3>DISPUTE RESOLUTION</h3>
        <p>
          In the event of any dispute, controversy or claim of whatsoever nature
          arising under, out of or in connection with or relating to
          interpretation, enforcement or performance of the terms and conditions
          of this agreement, such dispute, controversy or claim shall be
          governed and construed in accordance with the laws of India and the
          courts at New Delhi shall have the exclusive jurisdiction to
          adjudicate such dispute, controversy or claim.
        </p>
        <h2>PRIVACY POLICY</h2>
        <p>
          Here at Parijat Industries (India) Pvt. Ltd. (hereinafter referred to
          as Parijat) we value our App user’s privacy and it is important for us
          to protect it, hence we take a proactive approach and accordingly we
          have formulated these privacy policies which are mentioned herein
          below. The objective of these Privacy Policies is to help you
          understand what data we collect, why we collect it and what we do with
          it. Thereupon it is highly recommended that the users must read these
          Privacy Policy to avoid any discrepancies. By accessing our App, you
          are accepting these Privacy policies. <br /> <br />
          These Privacy Polices may change or update from time to time as per
          the requirements, thus the App users must read them from time to time
          and these changes or updates may occur without prior notification, but
          if the changes are significant then in that case we shall provide a
          prior notice.
          <br />
          <br />
          We will adhere by the laws in place that create offences for
          unauthorized actions against computer systems and data. We will
          investigate any such actions with a view to prosecuting and/or taking
          civil proceedings to recover damages against those responsible.
          <br />
          <br />
          Whenever you access our App, we aim to provide you with access to your
          personal information. If that information is wrong, we strive to give
          you ways to update it quickly or to delete it – unless we have to keep
          that information for legitimate business or legal purposes. When
          updating your personal information, we may ask you to verify your
          identity before we can act on your request.
          <br />
          <br />
          We may reject requests that are unreasonably repetitive, require
          disproportionate technical effort (for example, developing a new
          system or fundamentally changing an existing practice), risk the
          privacy of others, or would be extremely impractical (for instance,
          requests concerning information residing on backup systems).
          <br />
          <br /> Where we can provide information access and correction, we will
          do so free of charge, except where it would require a disproportionate
          effort. We aim to maintain our services in a manner that protects
          information from accidental or malicious destruction. Because of this,
          after you delete information from our services, we may not immediately
          delete residual copies from our active servers and may not remove
          information from our backup systems.
          <br />
          <br /> Our Privacy Policy are based on six key principles, enumerated
          below
          <br />
          <br />
          <strong>Control:</strong> We will put you in control of your privacy
          with easy-to-use tools and clear choices.
          <br /> <strong>Transparency:</strong> We will be transparent about
          data collection and use so you can make informed decisions.
          <br /> <strong>Security:</strong> We will protect the data you entrust
          to us through strong security and encryption.
          <br /> <strong>Strong legal protections:</strong> We will respect your
          local privacy laws and fight for legal protection of your privacy as a
          fundamental human right.
          <br /> <strong>No content-based targeting:</strong> We will not use
          your email, chat, files or other personal content to target ads to
          you.
          <br /> <strong>Benefits to you:</strong> The collected data will be
          used for your benefit only and to make our services better, in order
          to make your experiences better.
          <br />
          <br />
        </p>
        <p>The following outlines our privacy policy.</p>
        <ol className="numberList">
          <li>
            Before or at the time of collecting personal information, we will
            identify the purposes for which information is being collected
          </li>
          <li>
            We will collect and use your personal information solely with the
            objective of fulfilling those purposes specified by us and for other
            compatible purposes, unless we obtain the consent of the individual
            concerned or as required by law.
          </li>
          <li>
            We will only retain personal information as long as necessary for
            the fulfillment of those purposes.
          </li>
          <li>
            We will collect personal information by lawful and fair means and,
            where appropriate, with the knowledge or consent of the individual
            concerned.
          </li>
          <li>
            Personal data should be relevant to the purposes for which it is to
            be used, and, to the extent necessary for those purposes, should be
            accurate, complete, and up-to-date.
          </li>
          <li>
            We will protect personal information by reasonable security
            safeguards against loss or theft, as well as unauthorized access,
            disclosure, copying, use or modification.
          </li>
          <li>
            We will make readily available to customers information about our
            policies and practices relating to the management of personal
            information
          </li>
          <li>
            We will share personal information with companies, organizations or
            individuals outside Parijat if we have a belief in good faith that
            access, use, preservation or disclosure of the information is
            reasonably necessary to:
            <ul>
              <li>
                meet any applicable law, regulation, legal process or
                enforceable governmental request.
              </li>
              <li>
                enforce applicable Terms of Service, including investigation of
                potential violations
              </li>
              <li>
                detect, prevent or otherwise address fraud, security or
                technical issues
              </li>
              <li>
                protect against harm to the rights, property or safety of
                Parijat, our users or the public, as required or permitted by
                law.
              </li>
            </ul>
          </li>
          <li>
            If Parijat is involved in a merger, acquisition or asset sale, we
            will continue to ensure the confidentiality of any personal
            information and give affected users notice before personal
            information is transferred or becomes subject to a different privacy
            policy <br />
            <br />
            We are committed to conducting our business in accordance with these
            principles in order to ensure that the confidentiality of personal
            information is protected and maintained. We regularly review our
            compliance with these Privacy Policies and in case we receive formal
            written complaints then we shall contact the person who made the
            complaint to follow up. We work with the appropriate regulatory
            authorities, including local data protection authorities, to resolve
            any complaints regarding the transfer of personal data that we
            cannot resolve with our users directly
          </li>
        </ol>
      </div>
      <div className="radioGroupCover">
        <RadioGroup
          name="agreement"
          items={items}
          onChange={handleChange}
          value={value}
          error={error}
        />
      </div>
    </div>
  );
}
