import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  let isLogin = localStorage.getItem('loginStatus');
  return (
    <Route
      {...rest}
      component={(props) =>
        isLogin ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { refer: props.location },
            }}
          />
        )
      }
    />
  );
};

const mapStateToProps = ({ login: { isLogin } }) => ({
  isLogin,
});

export default connect(mapStateToProps)(PrivateRoute);
