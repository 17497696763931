import React, { useState } from 'react';
import './productCard.scss';

const ProductCard = (props) => {
  const {
    image,
    name,
    id,
    cropId,
    nextButtonText,
    type,
    isSubCategory = null,
    isSubChildCategory = null,
    level = '',
  } = props.data;
  const { nextText = '' } = props;
  return (
    <div
      key={id}
      className="productCard"
      onClick={() => props.handleClick && props.handleClick(props.data)}
    >
      <div className="card__imgCvr">
        <img src={image} alt={name} />
      </div>
      <div className="card__txt">{name}</div>
      <button
        type="button"
        //onClick={() => props.handleClick && props.handleClick(props.data)}
      >
        {nextText}
      </button>
    </div>
  );
};

export default ProductCard;
