import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./tabs.scss";

export default (props) => {
  return (
    <Tabs>
      <TabList>
        <Tab>Today</Tab>
        <Tab>Tomorrow</Tab>
        <Tab>Next Week</Tab>
      </TabList>

      <TabPanel>
        {props.hourlyData.map((e, index) => {
          return (
            <div className="weatherCard" key={index}>
              <div className="topSection">
                {new Date(e.dt * 1000).toLocaleTimeString()}
              </div>
              <div className="middleSection">
                <img
                  src={`http://openweathermap.org/img/wn/${e.weather[0].icon}@2x.png`}
                  alt=""
                />
              </div>
              <div className="bottomSection">
                {Math.round(e.temp)}
                <sup>0</sup> C
              </div>
            </div>
          );
        })}
      </TabPanel>
      <TabPanel>
        {props.hourlyData.map((e, index) => {
          return (
            <div className="weatherCard" key={index}>
              <div className="topSection">
                {new Date(e.dt * 1000).toLocaleTimeString()}
              </div>
              <div className="middleSection">
                <img
                  src={`http://openweathermap.org/img/wn/${e.weather[0].icon}@2x.png`}
                  alt=""
                />
              </div>
              <div className="bottomSection">
                {Math.round(e.temp)}
                <sup>0</sup> C
              </div>
            </div>
          );
        })}
      </TabPanel>
      <TabPanel>
        {props.dailyData.map((e, index) => {
          return (
            <div className="weatherCard" key={index}>
              <div className="topSection">
                {new Date(e.dt * 1000).toLocaleDateString("default", {
                  weekday: "short",
                })}
              </div>
              <div className="middleSection">
                <img
                  src={`http://openweathermap.org/img/wn/${e.weather[0].icon}@2x.png`}
                  alt=""
                />
              </div>
              <div className="bottomSection">
                {Math.round(e.temp.max)}
                <sup>0</sup> C
              </div>
            </div>
          );
        })}
      </TabPanel>
    </Tabs>
  );
};
