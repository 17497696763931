import React from "react";
import Select from "react-select";
import "./select.scss";

export default function CustomSelect({
  options,
  placeholder,
  name,
  id,
  onChange,
  error,
  value,
  ref,
  defaultValue,
  isDisabled,
  menuPlacementText,
}) {
  return (
    <>
      <Select
        ref={ref}
        className="react-select-container"
        classNamePrefix="react-select"
        menuPlacement="auto"
        options={options}
        value={value}
        placeholder={placeholder}
        id={id}
        name={name}
        onChange={onChange}
        isDisabled={isDisabled}
        menuPlacement={menuPlacementText ? "top" : "bottom"}
        // defaultMenuIsOpen= {true}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: "#00a651",
            primary: "#00a651",
          },
        })}
      />
    </>
  );
}
