import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./home.scss";
import Card from "../../components/card/Card";
import { APICALL } from "api/ApiCall";
import { HOME } from "api/Constants";
import { getLanguageId } from "api/Utils";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import ReactPlayer from "react-player";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export const Home = () => {
  const classes = useStyles();
  const history = useHistory();
  const settings = {
    dots: true,
    arrows: true,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
  };

  const languageId = localStorage.getItem("languageId");
  const [banners, setBanners] = useState([]);
  const [menus, setMenus] = useState([]);
  const [nextText, setNextText] = useState("");
  const [ModataStateData, setModataStateData] = useState("");

  useEffect(async () => {
    const languageId = localStorage.getItem("languageId");
    const dashboardData = await APICALL(HOME, [
      { name: "languageId", value: getLanguageId() },
    ]);
    try {
      const data = dashboardData.data;
      const bannersList = dashboardData.banner;
      setNextText(dashboardData.Next);
      setBanners(bannersList);
      setMenus(data);
    } catch {
      console.log("Error");
    }
  }, []);

  const handleClick = async (key) => {
    const keyId = key.dashboardId;
    if (keyId == 1) {
      localStorage.setItem("key", keyId);
      history.push("/AllProducts", nextText);
    }
    if (keyId == 2) {
      localStorage.setItem("key", keyId);
      history.push("/Crops", nextText);
    }
    if (keyId == 3) {
      history.push("/AskAnExpert");
    }
    if (keyId == 4) {
      history.push("/Weather");
    }
  };
  console.log(ModataStateData);
  let closeImg = {
    cursor: "pointer",
    float: "right",
    marginTop: "5px",
    width: "20px",
  };
  return (
    <div>
      <div className="home">
        <div className="slider-cover">
          <Modal
            open={ModataStateData == "" ? false : true}
            onClose={() => setModataStateData("")}
            className={classes.modal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            BackdropComponent={Backdrop}
          >
            <div className="reactPlayerCover">
              <iframe
                src={ModataStateData}
                width="600"
                height="214"
                scrolling="no"
                frameBorder="0"
                allowFullScreen={true}
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              ></iframe>
              <span
                className="modalCloseButton"
                onClick={() => setModataStateData("")}
              >
                X
              </span>
            </div>
          </Modal>
          <Slider {...settings}>
            {banners &&
              banners.map((banner, i) => {
                return (
                  <div
                    className="slider-card"
                    key={i}
                    onClick={() => {
                      console.log("----", banner);
                      setModataStateData(banner.vdolink);
                    }}
                  >
                    {/* <a key={i} href={banner.vdolink} target="_blank"> */}
                    <img
                      src={banner.thumbnail}
                      className="slider-card__image"
                    />
                    {/* </a> */}
                  </div>
                );
              })}
          </Slider>
        </div>
        <div className="homeContent">
          {menus &&
            menus.map((menu) => (
              <Card
                click={() => handleClick(menu)}
                key={menu.dashboardId}
                menu={menu}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Home;
