import axios from "axios";
import React, { useEffect, useState } from "react";
import PageName from "../../components/PageName/PageName";
import { APICALL } from "api/ApiCall";
import { GETPDF } from "api/Constants";
import "./allPdf.scss";

const AllPdf = () => {
  const [pdfLink, setpdfLink] = useState("")
  const [LinkName, setLinkName] = useState("")
  const languageId = localStorage.getItem("languageId")
  const [name, setName] = useState('')
  useEffect(() => {
    var url_string = window.location.href;
    var url = new URL(url_string);
    console.log("URL", url.search)
    let urlName = (url.search).slice(1)
    setLinkName(urlName)
    //var cId = url.searchParams.get("id");
    switch (urlName) {
      case "Term_of_use":
        getPdfLink(0);
        setName(languageId == 0 ? "Term of use" : "शर्तें");
        break;
      case "Privacy_Policy":
        getPdfLink(2)
        setName(languageId == 0 ? "Privacy Policy" : "निजता");
        break;
      case "Safety":
        getPdfLink(3)
        setName(languageId == 0 ? "Safety" : "सुरक्षा");
        break;
      default:
        alert("Something Went Wrong")
    }
  }, [])

  const getPdfLink = async (id) => {
    const pdfData = await APICALL(GETPDF, []);
    let path = pdfData.data[Number(id)].ImgPath;
    const pdgPath = String(path).replace(/ /g,'')
    setpdfLink(pdgPath);
  }
  return (
    <>
      <div className="Pdf">
        <PageName
          name={name}
        />
        <div className="Pdf-page">
          <div className="Pdf-page__cover">
            {pdfLink && pdfLink != "" && (
              <object
                data={pdfLink}
                type="application/pdf"
                width="100%"
                height="100%"
              ></object>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AllPdf;
