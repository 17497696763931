import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import PageName from "../../components/PageName/PageName";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./symptomsAndSolutions.scss";
import { APICALL } from "api/ApiCall";
import { SymptomsSolution } from "api/Constants";
import ReactHtmlParser from "react-html-parser";

// modal
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { getLanguageId } from "api/Utils";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1, 1, 1),
  },
}));

const SymptomsAndSolutions = (props) => {
  const [itemDetails, setItemDetails] = useState([]);
  const languageId = localStorage.getItem("languageId");
  const [solutionData, setsolutionData] = useState([]);
  const [pathData, setPathData] = useState([]);
  const [breadCrumbData, setbreadCrumbData] = React.useState([]);

  //modal
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [modalData, setModalData] = useState([]);

  const handleOpen = (slide) => {
    console.log(slide);
    setModalData(slide.symptomImgPath);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const settings = {
    dots: true,
    arrows: true,
    autoplay: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    nextArrow: (
      <div>
        <div className="next-slick-arrow">
          <span>
            {itemDetails && itemDetails.Label && itemDetails.Label[0]["Next"]}
          </span>
          <img
            src={process.env.PUBLIC_URL + "/prev.svg"}
            className="slider-card__image"
          />
        </div>
      </div>
    ),
    prevArrow: (
      <div>
        <div className="prev-slick-arrow">
          <img
            src={process.env.PUBLIC_URL + "/next.svg"}
            className="slider-card__image"
          />
          <span>
            {itemDetails &&
              itemDetails.Label &&
              itemDetails.Label[0]["Previous"]}
          </span>
        </div>
      </div>
    ),
  };

  var url_string = window.location.href;
  var url = new URL(url_string);
  var c = url.searchParams.get("id");
  let itemvalues = c.split("_");
  const id = itemvalues[2];
  const cropId = itemvalues[0];
  const stageId = itemvalues[1];

  useEffect(async () => {
    window.scrollTo(0, 0);
    const productListData = await APICALL(
      SymptomsSolution,
      [
        { name: "languageId", value: getLanguageId() },
        { name: "cropId", value: cropId },
        { name: "stageId", value: stageId },
        { name: "majorPestId", value: id },
      ],
      [itemDetails]
    );
    setItemDetails(productListData.data);
    setsolutionData(productListData.data.SymptomsAndSolution[0].solutions);
    console.log("iiiiiiiiiiiiiiiii", productListData.data);
    handelPageBar();
    ///
    setbreadCrumbData(productListData.data.breadcrumbs);
  }, []);

  const handelPageBar = () => {
    let pathData1 = JSON.parse(localStorage.getItem("pathData"));
    setPathData(pathData1);
  };

  const handleClick = (productId) => {
    var url_string = window.location.href;
    var url = new URL(url_string);
    var c = url.searchParams.get("id");
    let itemvalues = c.split("_");
    const type = itemvalues[0];
    const level = itemvalues[1];
    const id = itemvalues[2];
    let data = { type, level, id };
    props.history.push(
      `/ProductDetails?id=${productId}_${2}&type=2`,
      breadCrumbData
    );
  };

  const goToBread = (data, index) => {
    var url_string = window.location.href;
    var url = new URL(url_string);
    var c = url.searchParams.get("id");
    let itemvalues = c.split("_");
    const cropId = itemvalues[3];
    //const level = itemvalues[4];

    const { name = "", level = "", id = "", type = "" } = data;
    console.log("data", data);

    //localStorage.removeItem("pathData");
    window.location.href = `/ProductsPage?id=${level}_${type}_${id}_${cropId}`;
    //window.location.href = `/ProductsPage?id=${index}`;
  };

  return (
    <>
      {itemDetails && (
        <>
          <PageName
            CurrentName="Symptoms and Solutions"
            data={breadCrumbData}
            type={2}
          />
          <div className="SymptomsAndSolutions-page">
            <h2 className="symSolTitle">
              {itemDetails.Label &&
                itemDetails.Label.length > 0 &&
                itemDetails["Label"][0]["Symptoms & Solutions"] &&
                itemDetails["Label"][0]["Symptoms & Solutions"]}
            </h2>
            <div className="slider-cover">
              <Slider
                {...settings}
                afterChange={(index) => {
                  console.log(itemDetails.SymptomsAndSolution[index].solutions);
                  setsolutionData(
                    itemDetails.SymptomsAndSolution[index].solutions
                  );
                }}
              >
                {itemDetails.SymptomsAndSolution &&
                  itemDetails.SymptomsAndSolution.map((slide, index) => (
                    <div className="slider-card" key={index}>
                      <div className="slider-card-cover">
                        <div className="slider-card-cover__left">
                          <img
                            src={slide.symptomImgPath[0].imgPath}
                            className="slider-card__image"
                            onClick={() => handleOpen(slide)}
                          />
                        </div>
                        <div className="slider-card-cover__right">
                          {ReactHtmlParser(slide.symptom)}
                        </div>
                      </div>
                    </div>
                  ))}
              </Slider>
            </div>
            <h3 className="solTitle">
              {itemDetails.Label ? itemDetails.Label[0].Solutions : null}
            </h3>
            <div className="slider-cover">
              <Slider {...settings}>
                {itemDetails.SymptomsAndSolution &&
                  solutionData.map((sol, index) => (
                    <div className="slider-card" key={index}>
                      <div className="productInfo">
                        <div className="productInfo__left">
                          <img src={sol.product_img} alt={sol.product_name} />
                          <p>{sol.product_name}</p>
                        </div>
                        <div className="productInfo__right">
                          <div className="detailsBox">
                            <p>{itemDetails.Label[0]["Technical Name"]}</p>
                            {ReactHtmlParser(sol.technical_name)}
                          </div>
                          <div className="detailsBox">
                            <p>{itemDetails.Label[0]["Dose"]}</p>
                            <p>{ReactHtmlParser(sol.dose)}</p>
                          </div>
                          <div className="detailsBox">
                            <p>{itemDetails.Label[0]["Dilution"]}</p>
                            <p>{ReactHtmlParser(sol.dilution)}</p>
                          </div>
                          <div className="detailsBox">
                            <p>{itemDetails.Label[0]["Direction"]}</p>
                            {ReactHtmlParser(sol.direction)}
                          </div>
                          <div className="detailsBox">
                            <p>{itemDetails.Label[0]["PHI"]}</p>
                            <p>{ReactHtmlParser(sol.phi)}</p>
                          </div>
                          <div className="detailsBox">
                            <p>{itemDetails.Label[0]["Pack Size"]}</p>
                            <p>{ReactHtmlParser(sol.packsize)}</p>
                          </div>
                          <div className="btnBox">
                            <button
                              type="button"
                              onClick={() => handleClick(sol.product_id)}
                            >
                              {itemDetails.Label[0]["Product Information"]}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </Slider>
            </div>
          </div>
          {/* modal */}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <div className="modalCover">
              <span className="modalCloseButton" onClick={handleClose}>
                X
              </span>
              <div className={classes.paper}>
                <Slider {...settings}>
                  {modalData.length > 0 &&
                    modalData.map((data) => {
                      console.log(data);
                      return (
                        <div
                          className="slider-card symSlider"
                          key={Math.random()}
                        >
                          <img src={data.imgPath} alt={data.imgTitle} />
                        </div>
                      );
                    })}
                </Slider>
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export default withRouter(SymptomsAndSolutions);
