import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import ProductCard from "../../components/ProductCard/ProductCard";
import Sidebar from "../../components/Sidebar/Sidebar";
import PageName from "../../components/PageName/PageName";
import "./productsPage.scss";
import { APICALL } from "api/ApiCall";
import {
  PRODUCTTYPELIST,
  ProductList,
  PRODUCTSUBCATEGORY,
  ProductSubChildCategory,
  CROPLIST,
  CropStageList,
  MajorPestDeficiency,
  SymptomsSolution,
} from "api/Constants";
import { render } from "@testing-library/react";
import { HOME } from "api/Constants";
import { getLanguageId } from "api/Utils";

class ProductsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listData: [],
      allData: [],
      productTypeId: 0,
      subChildCategoryId: 0,
      subCategoryId: 0,
      nextText: "",
      allItems: [],
      CropId: "",
      stageId: "",
      languageId: "",
      key: "",
      pathData: [],
    };
  }

  componentWillMount() {
    window.scrollTo(0, 0);
    this.setState(
      {
        languageId: localStorage.getItem("languageId"),
        key: localStorage.getItem("key"),
      },
      () => {
        // let getpathData = JSON.parse(localStorage.getItem("pathData"));
        // if (getpathData) {
        //   this.setState(
        //     {
        //       pathData: getpathData,
        //     },
        //     () => {
        //       console.log("qqqqq", getpathData);
        //       this.handleClick(getpathData);
        //     }
        //   );
        // } else {
        //   this.apiCall(this.state.key);
        // }
        this.handleRoute();
        this.onGetNextText();
      }
    );
  }

  handleRoute = () => {
    var url_string = window.location.href;
    var url = new URL(url_string);
    var c = url.searchParams.get("id");
    if (c) {
      let itemvalues = c.split("_");
      const level = itemvalues[0];
      const type = itemvalues[1];
      const id = itemvalues[2];
      const CropId = itemvalues[3];
      const getStageId = itemvalues[4];
      let data = { id: id, level: level, type: type,CropId:CropId,getStageId:getStageId };
      //let items = JSON.parse(localStorage.getItem("pathData"));
      this.setState({
        CropId : CropId
      },()=>{
        this.apiCall(type)
        this.handleClick(data);
      })
    } else {
      this.apiCall(this.state.key);
    }
  };

  onGetNextText = async () => {
    //{ location: { state } }
    // const { state } = this.props.location;
    const languageId = localStorage.getItem("languageId");
    const dashboardData = await APICALL(HOME, [
      { name: "languageId", value: getLanguageId() },
    ]);
    //const data = dashboardData.data;
    const bannersList = dashboardData.banner;
    //setNextText(dashboardData.Next);
    //setBanners(bannersList);
    //setMenus(data);
    this.setState({ nextText: dashboardData.Next });
  };

  apiCall = async (productType,setList) => {
    if (productType == 1) {
      const productListData = await APICALL(PRODUCTTYPELIST, [
        { name: "languageId", value: getLanguageId() },
      ]);
      let editData = productListData.data.map((e) => {
        let dt = {
          ...e,
          name: e.ProductTypeName,
          image: e.ImgPath,
          id: e.ProductTypeId,
          level: 1,
          type: 1,
        };
        return dt;
      });
      if(setList){
        this.setState({
          allData: editData,
        });
      }else{
        this.setState({
          listData: editData,
          allData: editData,
        });
      }
    }
    if (productType == 2) {
      const cropListData = await APICALL(CROPLIST, [
        { name: "languageId", value: getLanguageId() },
      ]);
      let editData = cropListData.data.Crop.map((e) => {
        let dt = {
          ...e,
          name: e.CropName,
          image: e.ImgPath,
          id: e.CropId,
          level: 1,
          type: 2,
        };
        return dt;
      });
      if(setList){
        this.setState({
          allData: editData,
        });
      }else{
        this.setState({
          listData: editData,
          allData: editData,
        });
      }
    }
  };

  getProductLevel2 = async (id) => {
    if (true) {
      document.getElementById(`${1 + "-all"}`).classList.remove("active");
      this.state.allItems.map((e, index) => {
        if (e.id == id) {
          document.getElementById(`${1 + "-" + id}`).classList.add("active");
        } else {
          document
            .getElementById(`${1 + "-" + e.id}`)
            .classList.remove("active");
        }
      });
    }
    var num = 0;
    var n = num.toString();
    const productSubCategoryListData = await APICALL(PRODUCTSUBCATEGORY, [
      { name: "productTypeId", value: id },
      { name: "LanguageId", value: getLanguageId() },
    ]);
    let editData = productSubCategoryListData.data.map((e) => {
      let dt = {
        ...e,
        name: e.subCategoryName,
        image: e.ImgPath,
        id: e.subCategoryId,
        level: 2,
        type: 1,
        ProductTypeId: id,
      };
      return dt;
    });
    this.setState({ listData: editData });
    //setItemsListData(editData);
  };

  getProductLevel3 = async (id) => {
    var num = 0;
    var n = num.toString();
    const productSubCategoryListData = await APICALL(ProductSubChildCategory, [
      { name: "subCategoryId", value: id },
      { name: "LanguageId", value: getLanguageId() },
    ]);
    let editData = productSubCategoryListData.data.map((e) => {
      let dt = {
        ...e,
        name: e.subChildCategoryName,
        image: e.ImgPath,
        id: e.subChildCategoryId,
        level: 3,
        type: 1,
      };
      return dt;
    });
    this.setState({
      listData: editData,
    });
  };

  getProductList = async (level) => {
    const { productTypeId, subCategoryId, subChildCategoryId } = this.state;
    const productListData = await APICALL(ProductList, [
      { name: "productTypeId", value: productTypeId },
      { name: "LanguageId", value: getLanguageId() },
      { name: "subCategoryId", value: subCategoryId },
      { name: "subChildCategoryId", value: 0 },
    ]);
    if (productListData.data) {
      let editData = productListData.data.map((e) => {
        let dt = {
          ...e,
          name: e.ProductName,
          image: e.ImgPath,
          id: e.ProductId,
          level: level,
          type: 1,
          goToDetail: true,
        };
        return dt;
      });
      this.setState({ listData: editData });
    } else {
      alert("No Data Found");
    }
  };

  ////////////////crop api
  getCropLevel2 = async (id) => {
    if (true) {
      document.getElementById(`${2 + "-all"}`).classList.remove("active");
      this.state.allData.map((e, index) => {
        if (e.id == id) {
          document.getElementById(`${2 + "-" + id}`).classList.add("active");
        } else {
          document
            .getElementById(`${2 + "-" + e.id}`)
            .classList.remove("active");
        }
      });
    }
    var num = 0;
    var n = num.toString();
    const productSubCategoryListData = await APICALL(CropStageList, [
      { name: "CropId", value: id },
      { name: "languageId", value: getLanguageId() },
    ]);
    let editData = productSubCategoryListData.data.map((e) => {
      let dt = {
        ...e,
        name: e.StageName,
        image: e.ImgPath,
        id: e.StageId,
        level: 2,
        type: 2,
      };
      return dt;
    });
    this.setState({ listData: editData });
  };

  getCropLevel3 = async (id, getcropId) => {
    var num = 0;
    var n = num.toString();
    const productSubCategoryListData = await APICALL(MajorPestDeficiency, [
      { name: "cropId", value: getcropId },
      { name: "stageId", value: id },
      { name: "languageId", value: getLanguageId() },
    ]);

    if(productSubCategoryListData.responseCode != 1){
      return alert("Error")
    }

    let editData = productSubCategoryListData.data.map((e) => {
      let dt = {
        ...e,
        name: e.mpdName,
        image: e.ImgPath,
        id: e.mpdId,
        level: 3,
        type: 2,
      };
      return dt;
    });
    this.setState({ listData: editData });
    console.log("level3 data", this.state);
  };

  handleClick = (data, sideMenu) => {
    if (sideMenu) {
      let dt = [];
      dt.push(data);
      this.setState({ pathData: dt });
    } else {
      this.setState({
        pathData: [...this.state.pathData, data],
      });
    }
    if (sideMenu) {
      const { type, id } = data;
      document.getElementById(`${type + "-all"}`).classList.remove("active");
      this.state.allItems.map((e, index) => {
        if (e.id == id) {
          document.getElementById(`${type + "-" + id}`).classList.add("active");
        } else {
          document
            .getElementById(`${type + "-" + e.id}`)
            .classList.remove("active");
        }
      });
    }
    const { id = "", level = "", type = "",getStageId, } = data;
    console.log("dtaaaaaaaaaaaa", data);
    //product
    if (data.goToDetail) {
      this.goToDetail(type, level, id);
      return;
    }
    if (type == 1) {
      if (level == 1) {
        const { ProductTypeId = "" } = data;
        if (data.isSubCategory == "1") {
          this.setState(
            {
              productTypeId: ProductTypeId,
            },
            () => {
              this.getProductLevel2(id);
            }
          );
        } else {
          this.setState(
            {
              productTypeId: ProductTypeId,
            },
            () => {
              this.getProductList(1);
            }
          );
        }
      }
      if (level == 2) {
        this.setState(
          {
            subCategoryId: data.subCategoryId,
          },
          () => {
            this.getProductList(2);
          }
        );
      }
    } //end product
    ///////////////////////////////////////////////////////////////////////////
    if (type == 2) {
      if (level == 1) {
        this.getCropLevel2(id);
        this.setState({ CropId: id });
      }
      if (level == 2) {
        //hit sub child cat id , level 3
        this.getCropLevel3(id, this.state.CropId);
        this.setState({ stageId: id });
      }
      if (level == 3) {
        this.goToDetail(type, level, id, this.state.CropId, this.state.stageId);
        //go to detail
        //getCropLevel3(id);
      }
    }
  };

  goToDetail = (type, level, id) => {
    let subCat = this.state.subCategoryId;
    let cropId = this.state.CropId;
    let getStageId = this.state.stageId;
    if (type == 1) {
      //alert(`/ProductDetails?id=${type}_${level}_${id}`);
      localStorage.setItem("pathData", JSON.stringify(this.state.pathData));
      window.location.href = `/ProductDetails?id=${type}_${level}_${id}_${subCat}`;
    }
    if (type == 2) {
      //props.history.push('/SymptomsAndSolutions', data);
      localStorage.setItem("pathData", JSON.stringify(this.state.pathData));
      window.location.href = `/SymptomsAndSolutions?id=${type}_${level}_${id}_${cropId}_${getStageId}`;
    }
  };

  clickOnAllItems = (type) => {
    this.setState({ pathData: [] });
    document.getElementById(`${type + "-all"}`).classList.add("active");
    this.state.allItems.map((e, index) => {
      document
        .getElementById(`${type + "-" + e.id}`)
        .classList.remove("active");
    });
    this.apiCall(type);
  };

  goToBread = (data) => {
    console.log("aaaaa", data);
    const { name = "", level = "", id = "", type = "" } = data;
    this.setState({ pathData: [] }, () => {
      this.handleClick(data);
    });
  };

  render() {
    const { key, allData = [], nextText = "", listData = [] } = this.state;
    return (
      <div>
        <PageName
          name={key == 1 ? "Products" : "Crops"}
          data={this.state.pathData}
          selectedBread={(data) => this.goToBread(data)}
        />
        <div className="productsPage">
          <Sidebar
            headingText={key == 1 ? "Product Categories" : "Crop Categories"}
            data={allData}
            type={key == 1 ? 1 : 2}
            setClickedItem={(data) => this.handleClick(data, true)}
            onLabelClick={(type) => this.clickOnAllItems(type)}
          />
          <div className="productsList">
            <div className="titleName">
              All {key == 1 ? "Products" : "Crops"}
            </div>
            <div className="allProductsCards">
              {listData.map((e, index) => (
                <ProductCard
                  key={index}
                  data={e}
                  handleClick={(data) => this.handleClick(data)}
                  nextText={nextText}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProductsPage;
