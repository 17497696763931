import React from "react";
import "./header.scss";

function Header() {
  const languageId = localStorage.getItem("languageId");
  const logout = () => {
    // localStorage.setItem("loginStatus", "");
    // localStorage.setItem("userId", "");
    localStorage.clear()
    window.location.reload();
  };
  let convertLanVal = languageId &&  languageId.replace(/'/g, " ").trim();
  console.log(convertLanVal);
  return (
    <header>
      <div className="headerCover">
        <div className="leftSection">
          <div className="img-cvr">
            <img
              src={process.env.PUBLIC_URL + "/parijat_logo.png"}
              alt="parijat logo"
              onClick={() => (window.location.href = "/Home")}
            />
          </div>
          <img
            className="totalELogo"
            src={process.env.PUBLIC_URL + "/header_bg.png"}
            alt="totalE logo"
          />
        </div>
        <div className="rightSection">
          <div className="helplineNumber">
            <img
              src={process.env.PUBLIC_URL + "/call.svg"}
              alt="parijat logo"
            />
            <p className="numberDetailsBox">
              <span className="numberText">
                {convertLanVal === "0"
                  ? "National Helpline Number"
                  : "राष्ट्रीय हेल्पलाइन नंबर"}
              </span>
              <span className="number">1800-12300-6141</span>
            </p>
            {/* <img
              src={process.env.PUBLIC_URL + "/call.gif"}
              alt="parijat logo"
            /> */}
          </div>
          <div
            className="setting"
            onClick={() => (window.location.href = "/Setting")}
          >
            <img
              src={process.env.PUBLIC_URL + "/setting.svg"}
              alt="setting icon"
            />
            <span>{convertLanVal === "0" ? "Setting" : "सेटिंग"}</span>
          </div>
          <div className="logout" onClick={logout}>
            <img
              src={process.env.PUBLIC_URL + "/logout.svg"}
              alt="logout icon"
            />
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
