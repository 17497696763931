import React, { useState, useEffect } from "react";
import PageName from "../../components/PageName/PageName";
import CustomInput from "../../ui-components/Input/Input";
import CropSelection from "../../components/CropSelection/CropSelection";
import CustomSelect from "../../ui-components/Select/Select";
import "./setting.scss";
import { toast } from "react-toastify";
import { APICALL } from "api/ApiCall";
import {
  STATELIST,
  DISTRICTLIST,
  LANGLIST,
  USERPROFILE,
  CROPNAME,
  EDITPROFILE,
} from "api/Constants";
import axios from "axios";
import { SERVER_URL } from "../../constant/Constant";
const Setting = () => {
  const languageId = localStorage.getItem("languageId");
  const [cropDetails, setCropDetails] = useState([
    {
      id: 0,
      cropCategory: "",
      crop: "",
      acre: "",
      cropOptions: [],
      status: "",
      rowId: "",
    },
  ]);
  const [stateList, setStateList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [langList, setLangList] = useState([]);
  const sizeRef = React.useRef();
  const [DistrictValue, setDistrictValue] = useState([]);
  const [cropCategory, setCropCategory] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [profileDetails, setProfileDetails] = useState(true);

  /////////////
  const [StateValue, setStateValue] = useState({});
  const [disticValue, setdisticValue] = useState({});
  const [LanValue, setLanValue] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [userName, setuserName] = useState("");

  ///
  const userId = localStorage.getItem("userId");
  const [values, setValues] = useState({
    name: "",
    state: "",
    district: "",
    language: "",
  });
  useEffect(async () => {
    let responseData = await APICALL(USERPROFILE, [
      {
        name: "userId",
        value: userId,
      },
    ]);
    try {
      const name = responseData.data.userDetail[0].name;
      const state = responseData.data.userDetail[0].StateId;
      const district = responseData.data.userDetail[0].districtId;
      const language = responseData.data.userDetail[0].LanguageId;
      const cropDetailData = responseData.data.cropDetail;
      //////////////////
      setuserName(name);
      let myData = [];
      let cid = 0;
      cropDetailData &&
        cropDetailData.map(async (e, idx) => {
          ////// api for grt data
          const cropNameData = await APICALL(CROPNAME, [
            {
              name: "cropCategoryId",
              value: e.cropCategoryId,
            },
          ]);
          const data = cropNameData.data;
          const options = data.map((d) => ({
            value: d.cropId,
            label: d.crop,
          }));
          let cropNameOption = options;
          ///////////
          let dt = {
            id: cid,
            cropCategory: {
              label: e.cropCategory,
              value: e.cropCategoryId,
            },
            crop: {
              label: e.crop,
              value: e.cropId,
            },
            acre: e.acre,
            status: e.status,
            rowId: e.rowId,
            cropOptions: cropNameOption,
          };
          myData.push(dt);
          cid++;
        });
      setCropDetails(myData);
      setValues({
        name,
        state,
        district,
        language,
      });
      setProfileDetails(responseData.data);
      ///////////// S
      const stateData = await APICALL(STATELIST, []);
      const options = stateData.data.map((e) => ({
        label: `${e.StateName}`,
        value: `${e.StateId}`,
      }));
      setStateList(options);
      let dataS = options.find((e) => e.value == state);
      setStateValue(dataS);
      //////////////---
      /////////////// D
      const districtData = await APICALL(DISTRICTLIST, [
        { name: "stateId", value: state },
      ]);
      const data2 = districtData.data;
      const optionsD = data2.map((d) => ({
        value: d.districtId,
        label: d.district,
      }));
      setDistrictList(optionsD);
      let dataD = optionsD.find((e) => e.value == district);
      setdisticValue(dataD);
      ////////////--
      //////////////////L
      const langData = await APICALL(LANGLIST, []);
      const langOptions = langData.data.map((e) => ({
        label: `${e.LanguageName}`,
        value: `${e.LanguageId}`,
      }));
      setLangList(langOptions);
      let dataL = langOptions.find((e) => e.value == language);
      setLanValue(dataL);
    } catch {
      console.log("Error");
    }
    //////////////
  }, []);

  ////////////////////////////////////////////////////////////

  const handleChangeInput = (e) => {
    const value = e.target.value;
    var letters = /^[a-zA-Z\s]*$/;
    let matched = value.match(letters);
    if (value == "" || (matched && value.length <= 50)) {
      setuserName(value);
    } else {
      toast.warn("Number and Special Character are not allowed");
    }
  };
  const handleChangeSelect = async (e = "", type) => {
    if (type == "state") {
      setStateValue(e);
      const districtData = await APICALL(DISTRICTLIST, [
        { name: "stateId", value: e.value },
      ]);
      const data = districtData.data;
      const options = data.map((d) => ({
        value: d.districtId,
        label: d.district,
      }));
      setDistrictList(options);
      //let dataD =  options.find(e=> e.value == district)
      setdisticValue(null);
    } else if (type === "district") {
      setdisticValue(e);
    } else if (type === "language") {
      setLanValue(e);
    }
  };
  //////////////////////////////////////////////////////////////////////Crop

  const addNewRow = () => {
    setIsSubmit(false);
    var maxid = 0;

    cropDetails.map(function (obj) {
      if (obj.id > maxid) maxid = obj.id;
    });
    setCropDetails([
      ...cropDetails,
      {
        id: maxid + 1,
        cropCategory: "",
        crop: "",
        acre: "",
        cropOptions: [],
        rowId: "",
        status: "",
      },
    ]);
  };

  const deleteRow = (id) => {
    let cropInfo = cropDetails.filter((item) => item.status !== "0");
    if (cropInfo.length < 2) {
      return;
    }
    let item = cropDetails.filter((item) => item.id == id);
    const {
      id: itemId = "",
      cropCategory = "",
      crop = "",
      acre = "",
      rowId = "",
      status = "",
      cropOptions = [],
    } = item[0];
    let newArray = [...cropDetails];
    newArray[id] = {
      id: itemId,
      cropCategory: cropCategory,
      crop: crop,
      acre: acre,
      status: "0",
      rowId: rowId,
      cropOptions: cropOptions,
    };
    setCropDetails(newArray);
  };

  const changeCropCatID = (selectedCat, id, options) => {
    let item = cropDetails.filter((item) => item.id == id);
    const {
      id: itemId = "",
      cropCategory = "",
      crop = "",
      acre = "",
      status = "",
      rowId = "",
    } = item[0];
    let newArray = [...cropDetails];
    newArray[id] = {
      id: itemId,
      cropCategory: selectedCat,
      crop: "",
      acre: acre,
      status: status,
      rowId: rowId,
      cropOptions: options,
    };
    setCropDetails(newArray);
  };

  const changeCropID = (selectedItem, id) => {
    let item = cropDetails.filter((item) => item.id == id);
    const {
      id: itemId = "",
      cropCategory = "",
      crop = "",
      acre = "",
      status = "",
      rowId = "",
      cropOptions,
    } = item[0];
    let newArray = [...cropDetails];
    newArray[id] = {
      id: itemId,
      cropCategory: cropCategory,
      crop: selectedItem,
      acre: acre,
      status: status,
      rowId: rowId,
      cropOptions: cropOptions,
    };
    setCropDetails(newArray);
  };

  const changeRakbaValue = (val, id) => {
    console.log("val...", val);
    var z1 = /^[0-9]*$/;
    if (!z1.test(val)) {
      return;
    }
    let item = cropDetails.filter((item) => item.id == id);
    const {
      id: itemId = "",
      cropCategory = "",
      crop = "",
      acre = "",
      status = "",
      rowId = "",
    } = item[0];
    let newArray = [...cropDetails];
    newArray[id] = {
      id: itemId,
      cropCategory: cropCategory,
      crop: crop,
      acre: val,
      status: status,
      rowId: rowId,
    };
    setCropDetails(newArray);
  };

  //////////////////////////////////////////////////////////////////////////////////// End crop

  const validateHandler = async (e) => {
    e.preventDefault();
    setIsSubmit(true);
    let data = [];
    cropDetails.map((e) => {
      let dt = {
        rowId: e.rowId,
        acre: e.acre,
        cropCategoryListId: e.cropCategory.value,
        cropId: e.crop.value,
        status: e.status,
      };
      data.push(dt);
    });
    ////////
    let acreStatus = false;
    let cropStatus = false;
    let cropCategoryListId = false;
    data.map((e) => {
      if (e.acre == "") {
        acreStatus = true;
      }
      if (!e.cropId) {
        cropStatus = true;
      }
      if (!e.cropCategoryListId) {
        cropCategoryListId = true;
      }
    });
    if (cropCategoryListId || cropStatus || acreStatus) {
      return;
    }
    ///////

    if (!disticValue) {
      return alert("District  is Required");
    }
    if (!StateValue) {
      return alert("State  is Required");
    }
    if (!LanValue) {
      return alert("Language  is Required");
    }

    let payload1 = {
      apikey: "2992c9debd79d6e9",
      userId: userId,
      name: userName,
      languageId: LanValue.value,
      stateId: StateValue.value,
      districtId: disticValue.value,
      cropdetail: data,
    };

    let payload = JSON.stringify(payload1);
    console.log("payload.....", payload);
    axios
      .post(`${SERVER_URL}${EDITPROFILE}`, payload)
      .then(({ data }) => {
        console.log("Data.............", data);
        if (data.responseCode == 1) {
          alert("Data Updated");
          window.location.reload();
        } else {
          alert("Something went wrong");
        }
      })
      .catch((e) => alert("Something went wrong"));
  };

  return (
    <div className="setting">
      <PageName name={`${languageId != 1 ? "Setting" : "सेटिंग"}`} />
      <div className="setting-page">
        <div className="setting-page__cover">
          <form onSubmit={validateHandler}>
            <h1>
              <span>My Profile</span>
              <div
                className="editButton"
                onClick={() => setDisabled(!disabled)}
              >
                <img
                  src={process.env.PUBLIC_URL + "/edit.svg"}
                  alt="edit icon"
                />
                <span>Edit</span>
              </div>
            </h1>
            <div className="form-group">
              <label>Name/नाम*</label>
              <CustomInput
                type="text"
                placeholder="Enter name"
                name="name"
                isDisabled={disabled}
                value={userName}
                onChange={handleChangeInput}
                pattern="\S(.*\S)?"
              />
              {isSubmit && userName == "" && (
                <span className="error">Name is required</span>
              )}
            </div>
            <div className="form-group">
              <label>State/राज्य*</label>
              <CustomSelect
                options={stateList}
                placeholder="Select"
                isDisabled={disabled}
                value={StateValue}
                onChange={(e) => {
                  handleChangeSelect(e, "state");
                }}
              />
              {isSubmit && StateValue == "" && (
                <p className="error">State error</p>
              )}
            </div>

            <div className="form-group">
              <label>District/जिला*</label>
              <CustomSelect
                id="district"
                options={districtList}
                onChange={(e) => handleChangeSelect(e, "district")}
                placeholder="Select"
                isDisabled={disabled}
                value={disticValue}
              />
              {isSubmit && !disticValue && (
                <p className="error">Please select District</p>
              )}
            </div>

            <div className="form-group">
              <label>Language/भाषा*</label>
              <CustomSelect
                options={langList}
                placeholder="Select"
                isDisabled={disabled}
                value={LanValue}
                onChange={(e) => handleChangeSelect(e, "language")}
              />
            </div>
            <div className="cropBoxCover">
              <CropSelection
                isSubmit={isSubmit}
                addNewRow={addNewRow}
                cropDetails={cropDetails}
                deteteRow={deleteRow}
                changeCropID={changeCropID}
                changeCropCatID={changeCropCatID}
                changeRakbaValue={changeRakbaValue}
                isDisabled={disabled}
              />
            </div>
            <div className="btn-cvr">
              <button
                type="submit"
                disabled={disabled}
              >
                Save & Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Setting;
