import "./App.css";
import Router from "../src/router/Router";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { Offline, Online } from "react-detect-offline";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <div className="App">
        <Provider store={store}>
          <Router />
        </Provider>
      <ToastContainer autoClose={2000} hideProgressBar={true} />
    </div>
  );
}

export default App;
