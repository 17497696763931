import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import ProductCard from "../../components/ProductCard/ProductCard";
import "./products.scss";
import { CROPLIST } from "api/Constants";
import { APICALL } from "api/ApiCall";
/////
import Sidebar from "../../components/Sidebar/Sidebar";
import PageName from "../../components/PageName/PageName";
import { HOME } from "api/Constants";
import { getLanguageId } from "api/Utils";

const Crops = () => {
  const [sideBarData, setSidebarData] = React.useState([]);
  const [ProductListData, setProductListData] = React.useState([]);
  const [nextText, setnextText] = React.useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchCropList();
    fetchNextText();
  }, []);

  const fetchCropList = async () => {
    const languageId = localStorage.getItem("languageId");
    const productListData = await APICALL(CROPLIST, [
      { name: "languageId", value: getLanguageId() },
    ]);
    console.log("...............", productListData.data.Crop);
    if (!productListData.data) return alert("No Data Found");
    let customizeProducts =
      productListData &&
      productListData.data.Crop.map((e) => {
        let dt = {
          ...e,
          name: e.CropName,
          image: e.ImgPath,
          id: e.CropId,
        };
        return dt;
      });
    setProductListData(customizeProducts);
  };

  const fetchNextText = async () => {
    const languageId = localStorage.getItem("languageId");
    const dashboardData = await APICALL(HOME, [
      { name: "languageId", value: getLanguageId() },
    ]);
    setnextText(dashboardData.Next);
  };

  const handlerItemClick = (data, sideBarItem = false) => {
    console.log(data);
    const { CropId = null } = data;
    window.location.href = `/CropStage?id=${CropId}`;
  };
  const languageId = localStorage.getItem("languageId");
  let convertLanVal = languageId.replace(/'/g, " ").trim();
  return (
    <div>
      <PageName
        name="Products"
        data={[]}
        type={2}
        //selectedBread={(data) => this.goToBread(data)}
      />
      <div className="productsPage">
        <Sidebar type={2} />
        <div className="productsList">
          <div className="titleName">
            {convertLanVal == 1 ? "सभी फसल श्रेणियाँ" : "All Crop Categories"}
          </div>
          <div className="allProductsCards">
            {ProductListData.map((e, index) => (
              <ProductCard
                key={index}
                data={e}
                handleClick={(data) => handlerItemClick(data)}
                nextText={nextText}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Crops;
