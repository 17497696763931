import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import ProductCard from "../../components/ProductCard/ProductCard";
import "./products.scss";
import { CropStageList, CROPLIST } from "api/Constants";
import { APICALL } from "api/ApiCall";
/////
import Sidebar from "../../components/Sidebar/Sidebar";
import PageName from "../../components/PageName/PageName";
import { HOME } from "api/Constants";
import { getLanguageId } from "api/Utils";

const CropStage = () => {
  const [sideBarData, setSidebarData] = React.useState([]);
  const [ProductListData, setProductListData] = React.useState([]);
  const [nextText, setnextText] = React.useState("");
  const [headerName, setheaderName] = React.useState("");
  const [breadCrumbData, setbreadCrumbData] = React.useState([]);
  const [title, setTitle] = React.useState("");

  useEffect(() => {
    fetchCropStageList();
    fetchCropList();
    fetchNextText();
  }, []);

  const fetchCropStageList = async () => {
    var url_string = window.location.href;
    var url = new URL(url_string);
    var urlParams = url.searchParams.get("id");
    const cropId = urlParams[0];
    if (!cropId) return alert("Something went wrong");
    const languageId = localStorage.getItem("languageId");
    const cropStageListData = await APICALL(CropStageList, [
      { name: "CropId", value: String(cropId) },
      { name: "languageId", value: getLanguageId() },
    ]);
    console.log(cropStageListData);
    setTitle(cropStageListData.title);
    if (!cropStageListData.data) return alert("No Data Found");
    try {
      let editData =
        cropStageListData.data &&
        cropStageListData.data.map((e) => {
          let dt = {
            ...e,
            name: e.StageName,
            image: e.ImgPath,
            id: e.StageId,
          };
          return dt;
        });
      setProductListData(editData);
      setbreadCrumbData(cropStageListData.breadcrumbs);
    } catch {
      console.log("Error");
    }
  };

  const fetchCropList = async () => {
    const languageId = localStorage.getItem("languageId");
    const productListData = await APICALL(CROPLIST, [
      { name: "languageId", value: JSON.stringify(languageId) },
    ]);
    if (!productListData.data) return alert("No Data Found");
    let customizeProducts =
      productListData &&
      productListData.data.Crop.map((e) => {
        let dt = {
          ...e,
          name: e.ProductTypeName,
          image: e.ImgPath,
          id: e.ProductTypeId,
        };
        return dt;
      });
    setSidebarData(customizeProducts);
    //////////
    var url_string = window.location.href;
    var url = new URL(url_string);
    var urlParams = url.searchParams.get("id");
    const cropId = urlParams[0];
    if (!cropId) return alert("Something went wrong");
    setheaderName(customizeProducts[cropId - 1].CropName);
  };

  const fetchNextText = async () => {
    const languageId = localStorage.getItem("languageId");
    const dashboardData = await APICALL(HOME, [
      { name: "languageId", value: getLanguageId() },
    ]);
    setnextText(dashboardData.Next);
  };

  const handlerItemClick = (data, sideBarItem = false) => {
    console.log("ProductsSubCat....", data, sideBarItem);
    var url_string = window.location.href;
    var url = new URL(url_string);
    var urlParams = url.searchParams.get("id");
    const cropId = urlParams[0];
    const { StageId = null } = data;
    //if(isSubChildCategory == 1){
    window.location.href = `/MajorPestDeficiencies?id=${cropId}_${StageId}`;
    //}else{
    //  window.location.href = `/ProductSubChildCategory?id=${productTypeId}_${0}`;
    // }
  };

  const handlerAllItemClick = (type) => {
    window.location.href = `/AllProducts`;
  };
  return (
    <div>
      <PageName
        name="Products"
        data={breadCrumbData}
        type={2}
        //selectedBread={(data) => this.goToBread(data)}
      />
      <div className="productsPage">
        <Sidebar type={2} />
        <div className="productsList">
          <div className="titleName">{title}</div>
          <div className="allProductsCards">
            {ProductListData.map((e, index) => (
              <ProductCard
                key={index}
                data={e}
                handleClick={(data) => handlerItemClick(data)}
                nextText={nextText}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CropStage;
