let loader;

const getLoader = () => {
  if (loader) {
    return loader;
  }
  loader = document.createElement('div');
  loader.id = 'global-loader';
  loader.classList.add('loading-box', 'global-loader');
  loader.innerHTML = `<img class="loader-logo" src=${process.env.PUBLIC_URL + "/loader.gif"} alt="CNH"><div class="loader"></div>`;
  document.getElementById('root').appendChild(loader);
  return loader;
};

/**
 * Show loader (eg. call when api call)
 */
const show = () => {
  const loader = getLoader();
  loader.style.display = 'block';
};

/**
 * Hide loader (eg. call after api call)
 */
const hide = (props) => {
  const loader = getLoader();
  loader.style.display = 'none';
};

export default { show, hide };
