import React, { Fragment, useState } from "react";
import {
  BrowserRouter as Routers,
  Switch,
  Route,
  withRouter,
} from "react-router-dom";
import Login from "../pages/Login/index";
import Home from "../pages/Home/Home";
import AskAnExpert from "../pages/AskAnExpert/AskAnExpert";
import Weather from "../pages/Weather/Weather";
import Setting from "../pages/Setting/Setting";
import AllPdf from "../pages/AllPdf/AllPdf";
import ProductsPage from "../pages/ProductsPage/ProductsPage";
//import ProductTypeId from "../pages/ProductTypeId/ProductTypeId";
import SymptomsAndSolutions from "../pages/SymptomsAndSolutions/SymptomsAndSolutions";
import ProductDetails from "../pages/ProductDetails/ProductDetails";
import PrivateRoute from "./PrivateRoute";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
///
import AllProducts from "../pages/Products/Products";
import ProductsSubCat from "../pages/ProductSubCategory/ProductSubCategory";
import ProductTypeId from "../pages/ProductTypeId/ProductTypeId";
import Crops from "pages/Crops/Crops";
import CropStage from "pages/CropStage/CropStage";
import MajorPestDeficiencies from "pages/MajorPestDeficiencies/MajorPestDeficiencies";
import NotFound from "../pages/NotFound";

///

const Routes = withRouter(({ location }) => {
  const loginStatus = localStorage.getItem("loginStatus");
  console.log("loginStatus-----",loginStatus,"===---",loginStatus || location.pathname !== "/")
  return (
    <>
      {(loginStatus) && <Header />}
      <Switch>
        {loginStatus && <PrivateRoute path="/" exact={true} component={Home} />}
        <Route path="/" exact component={Login} />
        <PrivateRoute path="/Home" exact={true} component={Home} />
        <PrivateRoute
          path="/askANExpert"
          exact={true}
          component={AskAnExpert}
        />
        <PrivateRoute
          exact
          path="/SymptomsAndSolutions"
          component={SymptomsAndSolutions}
        />
        <PrivateRoute exact path="/ProductDetails" component={ProductDetails} />
        <PrivateRoute exact path="/Weather" component={Weather} />
        <PrivateRoute exact path="/Setting" component={Setting} />
        <PrivateRoute exact path="/Pdf" component={AllPdf} />

        {/* products */}
        <PrivateRoute exact path="/ProductTypeId" component={ProductTypeId} />
        <PrivateRoute exact path="/AllProducts" component={AllProducts} />
        <PrivateRoute exact path="/ProductsSubCat" component={ProductsSubCat} />
        <PrivateRoute
          exact
          path="/ProductSubChildCategory"
          component={ProductTypeId}
        />

        {/* crops */}
        <PrivateRoute exact path="/Crops" component={Crops} />
        <PrivateRoute exact path="/CropStage" component={CropStage} />
        <PrivateRoute
          exact
          path="/MajorPestDeficiencies"
          component={MajorPestDeficiencies}
        />
        <Route path="*" exact={true} component={NotFound} />
      </Switch>
      {loginStatus && <Footer />}
    </>
  );
});

const Router = () => {
  return (
    <Fragment>
      <Routers>
        <Routes />
      </Routers>
    </Fragment>
  );
};

export default Router;
