import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import './card.scss';

const Card = ({ menu, click }) => {
  return (
    <div className="card" onClick={() => click(menu)}>
      <div className="card__imgCvr">
        <img src={menu.ImgPath} alt={menu.menuName} />
      </div>
      <div className="card__txt">{menu.menuName}</div>
    </div>
  );
};

export default withRouter(Card);
